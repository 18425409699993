.block-text {
  margin: 50px 0;
  &.m--bg-items {
    margin: 0;
    padding: 50px 0;
    background-color: $beige-2;
    position: relative;
    overflow: hidden;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 195px;
      background-size: cover;
      z-index: 1;
      top: 0px;
    }

    &::before {
      background-image: url('../../images/before-items.png');
      right: calc(100vw - 32px);
    }

    &::after {
      background-image: url('../../images/after-items.png');
      left: calc(100vw - 32px);
    }

  }

}

@media all and (min-width: $bp-m) {
  .block-text {
    margin: 60px 0;
  }

}

@media all and (min-width: $layout-width) {
  .block-text.m--bg-items {
    &::before {
      right:calc(50vw + #{$layout-width}/2 - 32px);
    }

    &::after {
      left:calc(50vw + #{$layout-width}/2 - 32px);
    }

  }

}

