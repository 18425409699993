.contact-index-index {
  .page-main > .columns {
    @extend .l-wrapper;
  }

  .column:not(.sidebar-additional) .form.contact {
    background-color: $gray-l;
    border: 2px solid $beige;
    border-radius: 16px;
    margin: 30px auto 50px;
    padding: 32px 30px;
    width: 100%;

    .fieldset {
      display: flex;
      flex-flow: row wrap;
      gap: 20px;

      .field {
        flex-basis: 100%;
        flex-grow: 1;
        margin-bottom: 0;

        &.email, &.telephone {
          flex-basis: 300px;
        }
      }
    }

    .field.captcha {
      &.refreshing {
        img {
          object-fit: cover;
          height: 50px;
          width: 200px;
        }
      }
      button {
        font-size: 12px;
        &:hover, &:active, &:focus {
          background: none;
          box-shadow: none;
          color: $black;
          text-decoration: underline;
        }
      }
    }
  }
}
