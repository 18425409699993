/**
* Footer
*/
.ldv-reinsurrance {
  background-color: $gray-l;
  padding: 25px 0 45px;
  position: relative;
  .sv-g {
    > div:nth-child(odd) {
      position: relative;
      &::after {
        content: '';
        display: block;
        height: 70px;
        width: 1px;
        background-color: $beige;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        position: absolute;
      }

    }

  }

}

.ldvr__element {
  padding: 10px;
  text-align: center;
}

.ldvr__element-inner {
  max-width: 90%;
  margin: auto;
}

.ldvr__element-text {
  color: $blue;
  strong {
    font-weight: $fw-bold;
    font-family: $title-font-stack;
    font-size: 14px;
  }

  small {
    display: block;
    font-size: 12px;
  }

}

.ldvr__element-img-cont {
  height: 70px;
  img {
    max-height: 100%;
    width: auto;
  }

}

.ldv-footer-top {
  background-color: $blue;
  color: $white;
  padding: 52px 0 32px;
  text-align: center;
  font-size: 14px;
  position: relative;

  strong {
    display: inline-block;
    line-height: 32px;
  }
}

.ldvft__cont {
  padding: 0 32px;
  position: relative;
}

.ldvft__menu {
  display: none;
}

.ldvft__menu,
.ldvft__other-pages {
  a {
    display: inline-block;
    line-height: 32px;
  }
}

.ldvft__logo {
  margin-bottom: 24px;
}

.ldvft__socials {
  position: relative;
  padding: 16px 0;
  margin: 24px 0;
  p {
    margin-bottom: 12px;
  }

  a {
    font-size: 24px;
    display: inline-block;
    margin: 0 5px;
  }

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    background-color: $white;
    width: 155px;
    height: 1px;
    left: 50%;
    transform: translate(-50%,0);
  }
  &::before{
    top: 0;
  }
  &::after {
    bottom: 0;
  }
}

.ldvft__address {
  p:first-child {
    margin-bottom: 24px;
  }

}

.ldv-footer-bottom {
  background-color: $blue-d;
  text-align: center;
  color: $white;
  font-size: 14px;
  padding: 24px;
  a, strong {
    font-weight: $fw-regular;
    display: block;
    margin-bottom: 5px;
  }

}

.ldvfb__cont {
  max-width: 530px;
  margin: auto;

  .item {
    margin: 0;
  }

}

.ldvft__back-to-top {
  padding-left: 45px;
  position: absolute;
  top: -52px;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  font-family: $title-font-stack;
  white-space: nowrap;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 16px;
    top: 50%;
    width: 18px;
    height: 18px;
    transform: translate(0, -50%) rotate(270deg);
    @include background-svg($svg-arrow, $white);
  }

}

.ldvft__mini-back-to-top {
  position: fixed;
  bottom: 8px;
  right: 8px;
  z-index: 10;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: block;
  padding: 0;
  transition: opacity ease 0.3s, background-color 0.3s ease;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 18px;
    height: 18px;
    transform: translate(-50%, -50%) rotate(270deg);
    @include background-svg($svg-arrow, $white);
  }

}

@media all and (min-width: $bp-m) {
  .ldv-reinsurrance {
    &::before {
      content: '';
      top: 92px;
      left: 0;
      width: 100%;
      height: 25px;
      background-color: rgba($brown, 0.1);
      position: absolute;
      display: block;
      z-index: 1;
    }

    &::after {
      content: '';
      top: 103px;
      left: 0;
      width: 6px;
      height: 2px;
      position: absolute;
      display: block;
      z-index: 1;
      $shadows: 0 0 0 0 $white;
      $distance: 18;
      @for $i from 1 through 200 {
        $shadows: #{$shadows}, #{$distance*$i}px 0 0 0 $white;
      }

      box-shadow: $shadows;
      z-index: 2;
    }

    .l-wrapper {
      position: relative;
      z-index: 3;
    }

    .sv-g {
      > div:nth-child(odd)::after {
        display: none;
      }

    }

  }

  .ldvr__element-img-cont {
    margin-bottom: 25px;
  }

  .ldv-footer-top {
    text-align: left;
  }

  .ldvft__logo,
  .ldvft__menu,
  .ldvft__other-pages,
  .ldvft__socials,
  .ldvft__address {
    display: inline-block;
    margin: 0 15px;
  }

  .ldvft__menu,
  .ldvft__other-pages,
  .ldvft__address {
    a:hover {
      text-decoration: underline;
    }
  }

  .ldvft__cont {
    display: flex;
    max-width: 1120px;
    margin: auto;
    justify-content: space-between;
  }

  .ldvft__socials {
    margin-top: 0;
    padding-top: 0;

    &::before, &::after {
      display: none;
    }
  }

  .ldv-footer-bottom {
    padding: 24px;
    a, strong {
      display: inline-block;
      margin: 5px 20px;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .ldvfb__cont {
    max-width: $layout-width;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .ldvft__back-to-top {
    left: auto;
    right: 32px;
    transform: translate(0, -50%);
  }

  .ldvft__mini-back-to-top {
    bottom: 15px;
    right: 15px;
  }

}

@media all and (min-width:$bp-l) {
  .ldv-reinsurrance {
    &::before {
      top: 120px;
    }

    &::after {
      top: 131px;
    }

  }

  .ldvr__element-img-cont {
    height: 100px;
  }

  .ldvr__element-text {
    strong {
      font-size: 16px;
    }

    small {
      font-size: 12px;
    }

  }

  .ldvft__separator {
    display: inline-block;
    width: 1px;
    background-color: $white;
    margin: 0 15px;
  }

}

