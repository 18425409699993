.product-teaser {
  align-self: stretch;
  background-color: $gray-l;
  border-radius: 16px;
  box-shadow: 0 0px 2px rgba($black, 0.05);
  display: inline-flex;
  flex-flow: column nowrap;
  flex-shrink: 0;
  overflow: hidden;
  padding: 10px 16px;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  width: 100%;

  &.m--bg-white {
    background-color: $white;
  }

  &.m--horizontal {
    .product-teaser__media {
      height: 150px;

      span, span img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .slick-slide & {
    display: flex !important;
  }
}

.product-teaser__media {
  @extend %product-image;
}

.product-teaser__excerpt {
  align-items: center;
  background-color: rgba($white,0.9);
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-style: italic;
  height: calc(100% + 15px);
  left: -16px;
  line-height: 1.5;
  opacity: 0;
  padding: 15px 30px 0 30px;
  position: absolute;
  top: -10px;
  transition: opacity 0.3s ease;
  width: calc(100% + 32px);

  div {
    margin: auto;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
  }
}

.product-teaser__picto {
  background-color: $white;
  border-radius: 14px;
  bottom: 10px;
  font-size: 14px;
  height: 28px;
  left: 0;
  line-height: 28px;
  overflow: hidden;
  padding-left: 18px;
  position: absolute;
  transform: translate(-14px);
  white-space: nowrap;
  max-width: 28px;
  z-index: 1;
  transition: all 0.3s ease;

  &.m--orange {
    color: $orange-d;
  }
  &.m--green {
    color: #20968D;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translate(-50%, -50%);
  }

  span {
    opacity: 0;
    font-size: 12px;
    font-weight: $fw-bold;
    margin-left: 0;
    transition: all 0.2s ease;
  }

  &:hover {
    max-width: 200px;
    padding-left: 28px;
    padding-right: 10px;
    span {
      opacity: 1;
    }
  }
}

.product-teaser__text {
  display: flex;
  flex-flow: column;
  flex: 1;
}

.product-teaser__title {
  color: $dark;
  font-family: $title-font-stack;
  font-size: 14px;
  font-weight: $fw-bold;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow: hidden;
}

.product-teaser__infos {
  font-size: 12px;
  list-style: none;
  margin-top: auto;
  margin-bottom: 18px;
  padding: 0;

  li {
    margin-bottom: 7px;
    overflow: hidden;
    padding-left: 0 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::before {
      display: none;
    }
  }
  .label {
    color: $brown;
    display: inline-block;
    font-weight: normal;
  }
  .value {
    display: inline;
    color: $blue-d;
    text-decoration: underline;
    font-weight: $fw-bold;
  }
}

.product-teaser__price-cont {
  border-top: solid 1px $beige;
  padding-top: 9px;
}

.product-teaser__price-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-teaser__price {
  display: inline-block;
  font-size: 26px;
  font-weight: $fw-bold;
}

.product-teaser__add-to-cart {
  border-radius: 100%;
  display: inline-block;
  background-color: $orange;
  color: $white;
  width: 36px;
  height: 36px;
  font-size: 20px;
  position: relative;
  transition: background-color 0.3s ease, font-size 0.3s ease;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background-color: $orange-d;
    color: $white;
    font-size: 22px;
  }
}
.product-teaser__excerpt-list {
  display: none;
}

@media all and (min-width:$bp-m) {
  .product-teaser {

    &:hover {
      box-shadow: 0 3px 6px rgba($black, 0.16);
      transform: translateY(-3px);

      .product-teaser__excerpt {
        opacity: 1;
      }
    }

    .m--list & {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .product-teaser__media {
        // min-width: 100px;
        text-align: left;
        img {
          height: 160px;
          width: auto;
        }
      }

      .product-teaser__excerpt {
        display: none;
      }

      .product-teaser__excerpt-list {
        display: block;
        font-size: 12px;
        font-style: italic;
      }

      .product-teaser__text {
        padding: 0 30px 0 25px;
        max-width: 530px;
        width: 70%;
      }

      .product-teaser__title {
        margin-top: 0;
      }

      .product-teaser__infos {
        margin-bottom: 0px;
        li {
          display: inline-block;
        }
      }

      .product-teaser__price-cont {
        flex-direction: column;
        border-top: none;
        justify-content: flex-end;
        margin-left: auto;
        min-width: 130px;
        position: relative;
        padding: 0 0 20px;
        align-self: stretch;

        &::before {
          content: '';
          display: block;
          top: 50%;
          left: 0;
          width: 1px;
          background-color: $beige;
          height: 80%;
          transform: translate(0, -50%);
          position: absolute;
        }
      }

      .product-teaser__price {
        margin: auto;
        padding: 0 20px;
      }
    }

    &.m--horizontal {
      padding-left: 160px;
      max-width: 350px;
      min-height: 180px;
      width: 350px;

      .product-teaser__media {
        height: calc(100% - 32px);
        left: 24px;
        position: absolute;
        top: 16px;
        width: 110px;
      }

      .product-teaser__excerpt {
        display: none;
      }

      .product-teaser__infos {
        margin-bottom: 9px;
      }
    }
  }
}
