.products-listing {
  margin: 24px 0;

  &.m--bg-gray {
    background-color: $gray-l;
    padding: 32px 0;
  }

  + .products-listing {
    margin-top: -24px;

    &.m--list {
      margin-top: -16px;
    }
  }

}

.products-listing__inner {
  margin: 0 -5px;

  .m--grid & {
    align-self: stretch;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
}

.products-listing__product {
  margin: 5px;
  width: 100%;
}

.products-listing__image {
  margin: 5px -27px;
}

@media all and (min-width:$bp-s) {

  .products-listing__inner {
    margin: 0 -5px;
  }

  .products-listing__product {
    margin: 5px;

    .m--grid & {
      width: calc(50% - 10px);
    }
  }
}

@media all and (min-width:$bp-m) {
  .products-listing__inner {
    margin: 0 -8px;
  }

  .products-listing__product {
    margin: 8px;

    .m--grid & {
      width: calc(33.3333% - 16px);
      > div {
        display: flex;
        height: 100%;
      }
    }
  }

  .products-listing__image {
    margin: 8px;
    img {
      border-radius: 16px;
    }
  }

  .block-products-list {
    .products-listing__product {
      width: calc(25% - 16px);
    }
  }

}

