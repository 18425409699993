.our-selection-slider {
  margin: 24px 0 48px;
}
.oss__inner {
  padding-bottom: 40px;
  position: relative;
}
.oss__title {
  text-align: center;
}

.oss__image {
  display: none;
}

.oss__text {
  margin-bottom: 20px;
}

.oss__slider {
  margin: 0 -8px;
  position: relative;
  width: 70%;

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    padding: 0 8px;
    width: 280px;

    &:not(.slick-active) {
      opacity: 0.6;
    }
  }

  &:not(.slick-initialized) {
    display: flex;
    justify-content: space-between;

    .product-teaser {
      width: 264px;

      &:nth-child(n-4) {
        margin: 0 8px;
      }
      &:nth-child(n+4) {
        display: none;
      }
    }
  }
}

.oss__arrows {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 6;
}

.oss__dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
}

@media all and (min-width:$bp-s) {
  .oss__slider {
    width: 85%;
  }
}

@media all and (min-width:$bp-m) {
  .oss__slider {
    width: calc(100% - 250px);
    &::before {
      position: absolute;
      right: 100%;
      top: 0;
      content: '';
      display: block;
      width: 50vw;
      height: 100%;
      background-color: $white;
      z-index: 1;
    }
  }
  .oss__text-cont {
    background-color: $white;
    flex-shrink: 0;
    margin-right: 30px;
    padding-top: 24px;
    position: relative;
    text-align: left;
    width: 195px;
    z-index: 2;
  }
  .oss__title {
    text-align: left;
  }
  .oss__image {
    display: block;
    width: 106px;
    height: 106px;
    object-fit: cover;
    border-radius: 100%;
  }
  .oss__inner {
    display: flex;
  }
}
