.our-ranges {
  padding: 85px 30px 30px;
  background-color: $beige-2;
}

.or__title {
  text-align: center;
  position: relative;
  z-index: 3;
}

.or__inner {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.or__element {
  color: $white;
  position: relative;
  margin: 0 0 8px;
  width: 100%;
  max-width: 340px;

  &:focus, &:hover, &:active, &:visited {
    color: $white;
  }

  &.m--carto {
    .or__element-inner {
      background-color: $blue-d;
    }
    .or__element-border {
      color: #225791;
    }
  }

  &.m--guides {
    .or__element-inner {
      background-color: $orange;
    }
    .or__element-border {
      color: #E05522;
    }
  }

  &.m--books {
    .or__element-inner {
      background-color: $cyan;
    }
    .or__element-border {
      color: #3AC1AF;
    }
  }
}

.or__element-inner {
  height: 180px;
  border-radius: 16px;
  position: relative;
  z-index: 2;
}

.or__element-border {
  position: absolute;
  top: 17px;
  left: 13px;
  height: calc(100% - 34px);
  width: calc(100% - 26px);

  svg {
    width: 100%;
    height: 100%;
  }

  .border-d {
    display: none;
  }
}

.or__illus {
  background: no-repeat center / contain;
  width: 60%;
  height: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.or__element-title {
  bottom: 50px;
  padding: 0 40px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.or__element-hovered {
  display: none;
  opacity: 0;
}

@media all and (min-width:$bp-m) {

  .our-ranges {
    position: relative;

    &.hover-carto {
      .or__bg.m--carto {
        opacity: 1;
      }

      .or__title {
        color: $white;
      }
    }

    &.hover-guides {
      .or__bg.m--guides {
        opacity: 1;
      }
      .or__title {
        color: $blue-d;
      }
    }

    &.hover-books {
      .or__bg.m--books {
        opacity: 1;
      }
      .or__title {
        color: $white;
      }
    }
  }

  .or__inner {
    max-width: 832px;
    margin: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .or__element {
    width: 31.25%;
    perspective: 1500px;
    transform: translateZ(0);

    &:hover {
      .or__element-inner {
        transform: rotateY(90deg);
        visibility: hidden;
        transition: transform 0.3s ease, visibility 0.3s step-end;
      }

      .or__element-hovered {
        display: block;
        opacity: 1;
      }
    }
  }

  .or__element-inner {
    backface-visibility: hidden;
    height: 495px;
    left: 0;
    position: relative;
    transform: rotateY(0);
    transform-style: preserve-3d;
    transition: transform 0.3s ease;
    width: 100%;
  }

  .or__illus {
    width: 130px;
    height: 250px;
  }

  .or__element-border {
    .border-m {
      display: none;
    }
    .border-d {
      display: block;
    }
  }

  .or__element-title {
    bottom: 42%;
    width: auto;
    left: 50%;
    padding: 0;
    text-align: center;
    transform: translate(-50%, 0);
    display: block;
  }

  .or__bg {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: opacity ease 0.3s;
  }

  .or__element-hovered {
    transition: opacity 0.3s ease;
    padding-right: 50px;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%,0);
    min-width: 170px;
    text-align: right;
  }

  .or__element-label {
    font-size: 32px;
    font-family: $title-font-stack;
    font-weight: $fw-bold;
    position: relative;
    line-height: 1.2;

    svg {
      display: block;
      position: absolute;
      left: 100%;
      font-size: 50px;
      top: 11px;
    }

  }

  .or__element-seemore {
    &:hover {
      text-decoration: underline;
    }
  }
}
