.customer-account-login, .customer-account-create, .customer-account-forgotpassword {
  .page-main > .columns {
    @extend .l-wrapper;
  }
}

.login-container {
  background-color: $gray-l;
  border: 2px solid $beige;
  border-radius: 16px;
  margin: 0 auto 50px;
  max-width: 750px;
  padding: 30px 0 0;

  .fieldset {
    .legend {
      font-size: 1.5rem;
    }
    &::after {
      font-size: 12px;
    }
  }
}

.block-customer-login {
  margin-bottom: 30px;
  padding: 0 20px;

  .block-content {
    border-bottom: 1px solid $beige;
    padding-bottom: 30px;
  }

  fieldset {
    & > .field {
      margin-bottom: 15px;
    }
  }

  .input-text {
    border-color: rgba($beige, 0.5);
  }

  .actions-toolbar {
    margin: 0 !important;
    text-align: center;
  }
  .action.secondary {
    float: none;
    margin-bottom: 10px;
  }
  .action.remind {
    color: $blue;
    font-size: 14px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .tooltip {
    &.wrapper {
      margin: 0 4px;
    }
    &.toggle {
      background-color: $gray-d;
      border-radius: 50%;
      color: $white;
      display: inline-block;
      font-size: 12px;
      height: 14px;
      line-height: 14px;
      text-align: center;
      width: 14px;
    }
    &.content {
      font-size: 12px;
      margin-left: -9px;
    }
  }
}

.block-new-customer {
  margin-bottom: 30px !important;
  padding: 0 20px;

  .block-content p {
    font-size: 14px;
    color: $gray-d;
  }

  .actions-toolbar {
    text-align: center;
  }
  .action.primary {
    float: none;
    width: 100%;
  }
}

.ldv-block-title {
  margin-bottom: 20px;
}

.choice-label {
  font-size: 12px;
  color: $gray-d;
}

@media all and (min-width:$bp-m) {

  .block-customer-login {
    border-right: 1px solid $beige;
    width: 50% !important;

    .block-content {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .block-new-customer {
    width: 50% !important;
  }

  .customer-account-create, .customer-account-forgotpassword {
    .block-customer-login {
      border-right: none;
      width: auto !important;
    }

    .form.create.account {
      width: 100%;
    }
  }
}


//#############################################################################

.account {
  .column.main {
    .block:not(.widget) {
      margin-bottom: 24px;

      .block-title {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin-bottom: 24px;

        > strong {
          color: $blue;
          font-family: $title-font-stack;
          font-size: 33px;
          font-weight: $fw-bold;
          margin: 0;

          + a {
            margin-left: auto !important;
          }
        }

        > a span {
          @extend .btn;
        }
      }

      .box:not(:last-child) {
        border-bottom: 1px solid $beige;
        padding-bottom: 18px;
      }

      .box-title {
        > span {
          color: $blue-d;
          font-size: 18px;
          font-weight: $fw-bold;
        }
      }

      .box-information, .box[class*=address] {
        .box-content :first-child::first-line {
          font-weight: $fw-bold;
        }
      }

      .box-actions {
        margin: 1em -0.5em 0;

        .action:not(.change-password) {
          @extend .btn;
          margin: 0.5em;
        }

        .change-password {
          color: $blue-d;
          margin-left: 0.5em;
          text-decoration: underline;

          &:hover, &:active, &:focus {
            text-decoration: none;
          }
        }
      }
    }

    .block[class*=addresses] .block-content, .block-order-details-view .block-content {
      background-color: $beige-2;
      border-radius: 16px;
      padding: 32px 24px 12px;

      p.empty {
        margin-bottom: 20px;
      }
    }

    .table {
      color: $blue-d;

      thead > tr > th, thead > tr > td {
        border-bottom: none;
        color: $dark-l;
        font-size: 12px;
        font-weight: $fw-regular;
        padding-bottom: 2px;
      }

      tbody {
        > tr:nth-child(odd) {
          background-color: $gray-l;
        }

        .col.total {
          text-align: right;
        }

        .col.actions {
          font-size: 12px;
          vertical-align: middle;

          .action {
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .account-nav-content {
    background-color: $gray-l;
    color: $blue-d;

    .item.current {
      color: $orange;
      cursor: default;

      a, strong {
        border-color: currentColor;
      }
    }

    .item .delimiter {
      margin: 15px 18px 15px 15px;
    }
  }

  .account-content {
    padding: 0 18px;
  }

  .form-address-edit .actions-toolbar .action {
    font-size: 1rem;
    padding: 10px 20px;
    line-height: normal;
  }

}


@media all and (min-width:$bp-m) {
  .account {
    .column.main {
      display: flex;
      flex-flow: row nowrap;

      .block:not(.widget) {
        .box:not(:last-child) {
          border-bottom: none;
          padding-bottom: 0;
        }
        .box:nth-child(odd) {
          border-right: 1px solid $beige;
        }
      }

      .block[class*=addresses] .block-content, .block-order-details-view .block-content {
        margin-left: -24px;
        padding-right: 0;
        margin-bottom: 48px;

        .box:last-child {
          padding-right: 24px;
        }
      }
    }

    .account-sidebar {
      background-color: $gray-l;
      width: 25%;
    }

    .account-nav-content {
      padding-top: 115px;
    }

    .account-content {
      padding: 20px 50px 60px 120px;
      min-width: 60vw;
    }
  }
}
