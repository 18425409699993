.ldv-404 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80vh;
  text-align: center;
  line-height: 1;
  padding: 40px 0;
}

.ldv-404__oups {
  font-family: $title-font-stack;
  font-size: 56px;
  font-weight: $fw-bold;
  color: $blue-d;
  position: relative;
  z-index: 2;
}

.ldv-404__title {
  font-family: $title-font-stack;
  font-size: 214px;
  font-weight: $fw-bold;
  color: $orange;
  margin-top: -40px;
}

.ldv-404__text {
  font-size: 18px;
  color: $dark;

  strong {
    font-weight: $fw-bold;
    font-size: 24px;
  }
}

@media all and (min-width:$bp-m) {
  .ldv-404 {
    padding: 80px 0;
  }
}

