.links-with-image {
  padding: 50px 0;
  color: $white;
  background-color: $blue-d;
}

.lwi__title {
  color: $white;
  font-size: rem(33);
  text-align: center;
}

.lwi__element {
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  color: $white;
  cursor: pointer;
  display: block;
  height: 180px;
  margin: 0 auto 8px;
  max-width: 360px;
  position: relative;
  z-index: 2;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($blue-d, 0.2);
    z-index: -1;
    border-radius: 16px;
    transition: background-color ease 0.3s;
  }

}

.lwi__bg-btn {
  background: no-repeat right center / contain;
  width: 200px;
  height: 160px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  right: 11px;
  bottom: 10px;
}

.lwi__element-text {
  bottom: 36px;
  line-height: 1;
  max-width: 245px;
  padding: 0 40px 0 20px;
  position: absolute;
  right: 5px;
  text-align: right;
  width: calc(100% - 5px);

  svg {
    position: absolute;
    width: 50px;
    height: 50px;
    display: block;
  }

  &.m--arrow-loop {
    svg {
      right: -7px;
      bottom: -8px
    }
  }

  &.m--arrow-curve {
    svg {
      right: 3px;
      bottom: -36px
    }
  }

  &.m--arrow-chicane {
    svg {
      right: -7px;
      bottom: -6px;
    }
  }

}

.lwi__element-title {
  text-shadow: 0 0 6px rgba($blue-d, 0.8);
  font-size: 24px;
  font-family: $title-font-stack;
  font-weight: $fw-bold;
}

.lwi__element-seemore {
  text-shadow: 0 0 6px rgba($blue-d, 0.8);
  text-decoration: underline;
}


@media all and (min-width:$bp-m) {
  .links-with-image {
    padding-bottom: 72px;
    position: relative;
  }

  .lwi__title {
    width: 30%;
    margin: 0 auto 30px;
  }

  .lwi__element {
    margin: 0;
    width: 30.592734%;
    padding-bottom: 42.065009%;

    &:nth-child(2n +1) {
      margin-top: -50px;
    }

    &:hover {
      &::before {
        background-color: rgba($blue-d, 0.6) ;
      }
    }
  }

  .lwi__bg-btn {
    background: no-repeat right center / contain;
    width: 200px;
    height: 160px;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    right: 11px;
    bottom: 10px;
  }

  .lwi__element-text {
    bottom: 33px;
    position: absolute;
    right: 25px;
    text-align: right;
    width: calc(100% - 25px);

    &.m--arrow-curve {
      bottom: 46px;
    }
  }

  .lwi__inner {
    display: flex;
    justify-content: space-between;
  }

}
