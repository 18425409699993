.ldv-sharebox {
  position: fixed;
  right: 24px;
  top: 50%;
  transform: translate(0, -50%);
  color: $white;
  z-index: 100;

  li {
    a, button {
      display: block;
      width: 32px;
      height: 32px;
      position: relative;
      background-color: $orange;
      svg {
        width: 17px;
        height: 17px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: width 0.3s ease, height 0.3s ease;
      }

      &:hover {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    &:last-child {
      a, button {
        height: 40px;
        @include background-svg($svg-bg-share, $orange);
        background-size: cover;

        svg {
          top: 16px;
        }
      }
    }

  }
}

@media all and (min-width: $bp-m) {
  .ldv-sharebox {
    right: 55px;

    li {
      a, button {
        width: 45px;
        height: 38px;
      }

      &:last-child {
        a, button {
          height: 57px;
          @include background-svg($svg-bg-share, $orange);
          background-size: cover;
          svg {
            top: 19px;
          }
        }
      }
    }
  }
}
