.ldv-range-header {
  margin-bottom: 32px;

  &.range-travel-accessories {
    background-color: #f4f1e6;
  }
}

.ldv-range-header__image {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 320px;
  height: 290px;

  .range-books & {
    background-position: 44% bottom;
  }

  .range-travel-accessories & {
    background-position: center -25%;
  }
}

.ldv-range-header__title {
  align-items: center;
  display: flex;
  height: 90px;
  justify-content: center;
  left: 0;
  padding: 10px 0;
  position: absolute;
  bottom: calc(100% + 22px);
  width: 100%;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100vw;
    height: 112px;
    right: 0;
    top: 0;
    background-color: inherit;
    z-index: -1;
  }

  .range-carto & {
    background-color: #f6c215;
  }
  .range-guides & {
    background-color: #f6c215;
  }
  .range-books & {
    background-color: #ffdf5d;
  }
  .range-travel-accessories & {
    background-color: #f4f1e6;
  }

  .h1-like {
    color: $blue-d;
    margin: 0;

    .range-travel-accessories & {
      color: $blue;
    }
  }
}

.ldv-range-header__filters {
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: relative;
  top: -23px;
  z-index: 1;
}

.btn-show-subfilters {
  background-color: $blue-d;
  color: $white;
  flex-shrink: 0;
  font-size: 46px;
  margin-left: 0.356em;
}

.ldv-range-header__illus-left, .ldv-range-header__illus-right {
  display: none;
  pointer-events: none;
}

@media all and (min-width: $bp-m) {
  .ldv-range-header {
    position: relative;
  }

  .ldv-range-header__image {
    background-position: calc(50% + 500px) bottom !important;
    background-size: auto 100%;
    height: 360px;
  }

  .ldv-range-header__title {
    border-top-right-radius: 64px;
    bottom: 123px;
    color: $blue-d;
    height: 122px;
    max-width: 824px;
    padding: 10px 64px 10px 350px;

    &::before {
      border-top-right-radius: 64px;
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      width: 100vw;
      height: 245px;
      right: 0;
      top: 0;
      background-color: inherit;
    }

    .range-books & {
      &::before {
        height: 100%;
      }
    }
    .range-travel-accessories & {
      background-color: transparent;
      max-width: 100%;
      padding-left: 64px;
      text-align: center;
    }
  }

  .ldv-range-header__filters {
    bottom: 24px;
    display: block;
    left: 0;
    position: absolute;
    width: 100%;
    top: auto;
    z-index: 10;
  }

  .btn-show-subfilters {
    display: none;
  }

  .ldv-range-header__illus-left, .ldv-range-header__illus-right {
    display: block;
    bottom: 0;
    position: absolute;
    height: 360px;
    z-index: 2;
  }
  .ldv-range-header__illus-left {
    right: 81.5%;
  }
  .ldv-range-header__illus-right {
    left: 81.5%;
  }


}

