.product-image-container {
  @extend %product-image;
}


.checkout-title {
  margin-bottom: 30px;

  h1 {
    display: inline-block;
    color: $blue-d;
    margin-right: 0.5em;
    margin-bottom: 0;
    vertical-align: baseline;
  }

  .secured-payment {
    display: inline-block;
    color: $orange;
    font-size: rem(16);
    font-weight: $fw-bold;
  }
}

.cart-container {
  clear: both;
  margin-bottom: 16px;

  .cart-summary {
    background-color: $gray-l;
    border-radius: rem(16);
    margin: rem(36) 0 rem(76) 0;
    position: relative;

    .summary.title {
      color: $blue-d;
      font-weight: $fw-bold;
      position: relative;
      margin: 0;
      top: rem(-36);
      font-size: rem(18);
    }

    #block-summary {
      display: block;
    }
    #shipping-zip-form {
      display: none;
    }

    .block .fieldset {
      margin: 0 0 0 5px;

      .methods .field > .label {
        color: $dark;
        font-size: 15px;
        font-weight: $fw-bold;
      }
    }

    .cart-totals {
      border: none;
      padding-top: 0;
    }

    .shipping {
      margin-top: 10px;
    }

    .checkout-methods-items .action.primary {
      color: $white;
      font-size: 16px;
      line-height: 1;
      padding: 15px 20px;
    }
  }

  .cart-summary-payment-methods {
    bottom: -48px;
    left: 50%;
    max-width: 100%;
    position: absolute;
    transform: translateX(-50%);
  }
}

.cart {

  .item {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    min-height: 156px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-left: 130px;
    position: relative;

    .product-item-photo {
      height: 140px;
      left: -130px;
      padding: 0;
      position: absolute;
      top: 0;
      width: 105px;

      .product-image-container {
        max-height: 100%;
        width: auto !important;
      }
    }

    .product-item-details {
      width: 100%;
      flex-shrink: 0;
    }

    .product-item-name {
      font-family: $title-font-stack;
      font-size: rem(14);
      font-weight: $fw-bold;
    }

    .subtotal {
      margin-left: auto;
    }
  }

  .input-text.qty {
    display: inline-block;
    margin-right: 0.5em;
    padding: 0 24px;
    width: 100px;
  }

  .item-qty {
    font-size: rem(16);
    font-weight: $fw-bold;
    margin-right: 0.5em;
  }

  .price-including-tax {
    font-size: rem(18);
  }

  .item-price {
    color: $gray-d;
    display: inline-block;
    font-size: rem(12);

    .price-including-tax {
      display: inline;
      font-size: inherit;
      margin: 0;
    }

    .price {
      font-weight: $fw-regular;
    }
  }

  .item-actions {
    .action-edit {
      display: none;
    }
    .action-delete {
      background-color: $gray-l;
      color: $gray-d;
      align-items: center;
      display: flex;
      height: rem(36);
      justify-content: center;
      margin: 0 0 0 1em;
      width: rem(36);
    }
  }
}

.cart-container .form-cart .action.update::before {
  position: relative;
  top: -1px;
  vertical-align: middle;
}

.checkout-container {
  .h3-like {
    font-size: 18px;
  }

  &.hide-errors {
    .message-error {
      display: none;
    }
  }

  .opc-wrapper {
    .step-title {
      @extend .h3-like;
      font-size: 18px;
      border: none;
      padding-bottom: 0;
    }
  }

  .opc-payment {
    margin-top: 3em;
  }

  .opc-sidebar {
    position: relative;
  }

  .opc-block-summary {
    background-color: $gray-l;
    border-radius: rem(16);
    margin: rem(36) 0 0 0;
    padding: 20px 15px 12px;

    > .title {
      border-bottom: none;
      color: $blue-d;
      font-weight: $fw-bold;
      margin: 0;
      position: absolute;
      top: 0;
      font-size: rem(18);
    }

    .totals {
      .mark {
        padding-left: 4px;
      }
      .amount {
        padding-right: 4px;
        text-align: right;
        white-space: nowrap;
      }
    }
  }

  .opc-sidebar-payment-methods {
    margin-top: 16px;
  }

  .actions-toolbar {
    margin-top: 20px;

    .primary {
      float: none;

      .action {
        line-height: 1em;
        font-size: 1em;
      }
    }
  }

  .checkout-payment-method .payment-method-content > .actions-toolbar > .primary .action.primary {
    line-height: 1em;
    font-size: 1em;
  }

  .payment-method-title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-height: 4em;

    input, label {
      cursor: pointer;
    }

    .label {
      flex: content;
      font-size: 16px !important;
    }
  }

  .payment-method .action-help {
    display: none;
  }

  .table-checkout-shipping-method {
    .row {
      cursor: pointer;
    }
  }
}

.cart-container, .checkout-container {
  position: relative;

  .totals {
    .mark {
      color: $dark-l;
      font-size: 12px;
      font-weight: $fw-bold;
      vertical-align: middle;
    }

    .mark strong {
      font-weight: $fw-bold;
    }

    .amount {
      color: $blue-d;
      font-size: 18px;
      font-weight: $fw-bold;
      vertical-align: middle;
    }

    &.grand {
      border-top: 1px solid $beige;

      .mark {
        font-size: 16px;
        padding-top: 15px;

        span {
          display: block;
          font-size: 12px;
          font-weight: $fw-regular;
        }
      }
      .amount {
        font-size: 26px;
        padding-top: 15px;
      }
    }
  }
}

.opc-progress-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 auto 16px;
  max-width: 230px;
  overflow: hidden;
  width: 100%;
}

.opc-progress-bar-item {
  background-color: $beige;
  border-radius: 50%;
  color: $white;
  font-weight: $fw-bold;
  height: 36px;
  position: relative;
  width: 36px;

  > span {
    display: block;
    font-size: 18px;
    font-family: $default-font-stack;
    height: 100%;
    padding: 0.2em 0 0;
    text-align: center;
    width: 100%;
  }

  &._complete, &._active {
    background-color: $blue-d;
  }

  &._complete {
    cursor: pointer;

    > span::after {
      @include background-svg($svg-check, $white);
      content: '';
      display: inline-block;
      height: 90%;
      width: 1em;
    }
  }

  &::before {
    content: none;
    background-color: inherit;
    height: 2px;
    left: auto;
    position: absolute;
    width: 100vw;
    right: 0;
    top: calc(50% - 1px);
    z-index: -1;
  }

  &:last-child()::before {
    content: '';
    z-index: -2;
  }


  &._active::before {
    content: '';
  }
}

#etep_form {
  @extend .l-wrapper;
  text-align: center;
  margin-top: 4em;
}

@media all and (min-width:$bp-m) {
  .cart-container {
    .cart-summary {
      width: 28.96%;
    }
    .form-cart {
      width: 66.35%;
    }
  }

  .checkout-container {
    .opc-sidebar {
      margin-top: 0;
      width: 28.96%;

      .table-wrapper:last-child {
        margin-bottom: 0;
      }
    }
    .opc-wrapper {
      padding: 0;
      width: 66.35%;
      max-width: 650px;

      .form {
        max-width: none;
      }

      .fieldset > .field {
        & > .label {
          font-size: 12px;
          font-weight: $fw-bold;
        }
        &._required, &.required {
          > .label::after {
            color: inherit;
            font-size: 1em;
          }
        }
      }
    }

    .fieldset.address {
      display: grid;
      column-gap: 30px;
      grid-template-columns: auto auto;

      .street, [name="shippingAddress.company"], [name="shippingAddress.country_id"] {
        grid-column: auto / span 2;
      }

      [name="shippingAddress.postcode"], [name="shippingAddress.city"] {
        grid-row: 4;
      }

      [name="shippingAddress.postcode"] {
        order: -1;
      }
    }
  }

  .opc-progress-bar-wrapper {
    position: absolute;
    right: 0;
    top: -84px;
    width: 28.96%;
  }

  .checkout-onepage-success {
    .checkout-title .more {
      text-align: left;

      .print {
        float: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .checkout-success {
      .actions-toolbar {
        margin-top: 1em;
      }
    }
  }
}
