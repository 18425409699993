.products-slider {
  &.m--bg-gray {
    background-color: $gray-l;
    padding: 32px 0;
  }

  .product-teaser {
    width: 265px !important;
  }
}

.ps__slider {
  position: relative;
  width: 70%;
  padding-bottom: 40px;
  margin: 0 -8px 56px;

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    padding: 0 8px;
    &:not(.slick-active) {
      opacity: 0.6;
    }
  }

  &:not(.slick-initialized) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;

    .product-teaser {
      &:nth-child(n-5) {
        margin: 0 8px;
      }
      &:nth-child(n+5) {
        display: none;
      }
    }
  }
}

.ps__arrows {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 6;
}

.ps__dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
}

@media all and (min-width:$bp-s) {
  .ps__slider {
    width: 85%;
  }

}

@media all and (min-width:$bp-m) {
  .ps__slider {
    &::before {
      position: absolute;
      right: 100%;
      top: 0;
      content: '';
      display: block;
      width: 50vw;
      height: 100%;
      background-color: $white;
      z-index: 1;
    }

  }

  .products-slider.m--bg-gray {
    .ps__slider {
      &::before {
        background-color: $gray-l;
      }

    }

  }

}

@media all and (min-width:$bp-l) {

  .ps__slider {
    margin: 56px -8px;
    width: 100%;
  }

}

