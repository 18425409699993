/**
 * ==================== @ BASE STYLES
 */

// CSS Reset
html {
  box-sizing: border-box;
  font-size: $default-font-size;
}

p {
  max-width: 100%; // Compatibility IE11
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  background-color: #fff;
  color: $dark;
  font: normal $fw-regular 1em $default-font-stack;
  min-width: 320px;
  overflow-x: hidden;

  &::before {
    background-color: rgba($black, .9);
    content: '';
    height: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity .25s, height 0s .25s;
    width: 100%;
    z-index: 2;
  }

  &.is-overlaid {
    &::before {
      height: 100%;
      opacity: 1;
      transition: opacity .25s;
    }
  }

  .ldv-nav-open & {
    overflow: hidden;
  }
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;

  .base {
    display: block;
  }
}

iframe {
  border: 0 none;
}

img {
  height: auto;
  max-width: 100%;
}

input,
textarea,
select {
  outline: none;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

[data-href] {
  cursor: pointer;
}

.leaflet-control-attribution svg {
  display: none !important;
}


// Text
.h1-like, .h2-like, .h3-like, .h4-like {
  font-weight: $fw-bold;
  line-height: 1.2;
  color: $dark;

  &.m--centered {
    text-align: center;
  }
}

.h1-like {
  font-size: rem(42);
  margin-bottom: 30px;
  font-family: $title-font-stack;
  color: $orange;
}

.h2-like {
  font-size: rem(36);
  margin-bottom: 30px;
  font-family: $title-font-stack;
  color: $blue;
}

.h3-like {
  font-size: rem(24);
  margin-bottom: 15px;
  color: $blue-d;
}

.h4-like {
  font-size: rem($default-font-size);
}


@media all and (min-width: $bp-l) {

  .h1-like {
    font-size: rem(56);
  }

  .h2-like {
    font-size: rem(42);
  }

  .h3-like {
    font-size: rem(24);
  }

  .h4-like {
    font-size: rem($default-font-size);
  }

  .title-bg {
    span {
      font-size: rem(32);
    }
  }

}
