.media-video {
  margin: 32px 0;
}

.media-video__wrapper {
  height: 0;
  border-radius: 8px;
  overflow: hidden;
  padding: 0 0 56.25% 0;
  position: relative;
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

}

.media-video__btn {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($blue-d, 0.6);
}

.media-video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  z-index: 2;
  font-size: 100px;
  color: rgba($white,0.4);
  transition: color 0.3s ease;
  &:hover {
    color: rgba($white, 0.8);
  }

  svg {
    display: block;
  }

}

.media-video__title {
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  font-size: 31px;
  font-family: $title-font-stack;
  width: 200%;
  text-align: center;
  color: $white;
  line-height: 1;
  font-weight: $fw-bold;
}

.media-video__icon {
  font-size: 16px;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -41%);
}

@media all and (min-width: $bp-m) {
  .media-video {
    margin: 64px 0;
  }

  .media-video__play {
    width: 180px;
    height: 180px;
    font-size: 180px;
  }

}

