/**
 * ==================== @ GENERIC MODIFIERS
 */

body {
  --context-dark: #{$white};
  --context-light: #{$white};
  --context-text: #{$dark};
}

.context-blue {
  --context-dark: #{$blue-d2};
  --context-light: #{$blue-d};
  --context-text: #{$white};
}

.context-orange {
  --context-dark: #{$orange-d};
  --context-light: #{$orange};
  --context-text: #{$white};
}

.context-green {
  --context-dark: #{$green-d};
  --context-light: #{$green};
  --context-text: #{$white};
}

