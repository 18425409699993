.catalogsearch-result-index {
  .page-main > .columns {
    @extend .l-wrapper;
  }

  .sidebar {
    position: relative;
    z-index: 1;
  }

  .toolbar-products {
    padding: 0;
    margin-bottom: 32px;
  }

  .toolbar-amount {
    color: $blue-d;
    float: none;
    font-size: rem(12);
    font-weight: $fw-bold;
    padding: 0;
  }
}

.ldv-search-header {
  margin-bottom: 2em;
}

.ldv-search-header__count {
  font-family: $title-font-stack;
  font-size: 24px;
  font-weight: $fw-bold;
  margin-top: -0.5em;
  text-align: center;
}

.ldv-search-header__form {
  margin: 1.5em auto;
  max-width: 400px;

  .minisearch {

    .input-text {
      border-color: $orange;
      border-width: 2px;
      transition: border-color 0.3s ease;

      &:focus {
        box-shadow: none;
      }
    }

    .actions button {
      background-color: $orange;
      color: $white;
    }
  }
}

.ldv-search-filters__label {
  display: none;
  color: $gray-d;
  font-size: 12px;
  font-weight: $fw-bold;
}

.ldv-search-filters {
  border: 2px solid $blue;
  box-shadow: none;
  max-width: 400px;
  width: 100%;

  .ldv-filters__item {
    + .ldv-filters__item::before {
      content: none;
    }
  }

}

.ldv-filters__clear {
  align-self: center;
  color: $gray-d;
  margin-left: 50px;
  max-height: 0;
  white-space: nowrap;

  svg {
    font-size: 20px;
    vertical-align: middle;
  }

  span {
    font-size: 12px;
    text-decoration: underline;
  }

  &.is-hidden {
    pointer-events: none;
    visibility: hidden;
  }
}

@media all and (min-width:$bp-m) {
  .catalogsearch-result-index {
    .m--grid .products-listing__product {
      width: calc(25% - 16px);
    }
  }

  .ldv-search-filters__label {
    display: block;
  }

  .ldv-search-filters {
    border: none;
    margin: 0;
    max-width: none;
    padding: 0;

    .ldv-filters__item {
      border: 1px solid $beige;
      border-radius: 25px;
      margin-right: 10px;

      .ldv-dropdown__content {
        border: 1px solid $beige;
        border-top: none;
        left: -1px;
        min-width: none;
        max-width: none;
        width: calc(100% + 2px);
      }

      &:not(.m--disabled):hover {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.m--disabled {
        background-color: $gray-l;
        color: $gray-d;

        .ldv-dropdown__title {
          opacity: 1;
        }
      }
    }
  }

  .ldv-filters__clear {
    max-height: none;
  }

}
