.ldv-page-header {
  overflow: hidden;
}

.ldv-page-header__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 200px;
}

.ldv-page-header__title {
  text-align: center;
  padding: 10px 0;

  .h1-like {
    margin: 0;
  }
}

.ldv-page-header__suptitle {
  font-size: 14px;
  font-family: $title-font-stack;
  font-weight: $fw-medium;
  height: 25px;
}

.ldv-page-header__chapo {
  text-align: center;
  font-size: 18px;
  font-weight: $fw-medium;
  max-width: 620px;
  margin: auto;
}

.ldv-simple-title {
  h1 {
    color: $blue-d;
  }
}

@media all and (min-width: $bp-m) {
  .ldv-page-header__image {
    height: 270px;
  }

  .ldv-page-header__title {
    max-width: 750px;
    margin: -64px auto 0 auto;
    border-top-right-radius: 64px;
    background-color: $white;
    position: relative;
    z-index: 1;
    padding: 10px 64px;
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      display: block;
      width: 50vw;
      height: 100%;
      right: 50%;
      top: 0;
      background-color: $white;
    }

  }

}

