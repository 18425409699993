.ldv-home-header {

}

.ldv-home-header__image {
  background-size: 100% auto;
  background-position: center center;
  min-height: 175px;
}

.ldv-home-header__bottom {
  background-color: $yellow;
  height: 80px;
  position: relative;

  img {
    position: absolute;
  }
}

.ldv-home-header__bottom-clouds {
  animation: homeheaderclouds 40s ease-in-out infinite alternate;
  left: 50%;
  top: -35px;
  margin-left: -55px;
}

.ldv-home-header__bottom-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ldv-home-header__bottom-illus {
  display: none;
}

@keyframes homeheaderclouds {
  from { transform: translateX(0); }
  to { transform: translateX(60px); }
}

@media all and (min-width:$bp-m) {

  .ldv-home-header__image {
    background-position: center 18%;
    min-height: 200px;
  }

  .ldv-home-header__bottom {
    height: 90px;
  }

  .ldv-home-header__bottom-clouds {
    left: 50%;
    top: auto;
    margin-left: 0;
  }

  .ldv-home-header__bottom-text {
    left: auto;
    right: 50%;
    transform: translateY(-50%);
  }

  .ldv-home-header__bottom-clouds, .ldv-home-header__bottom-illus {
    bottom: 0;
    display: block;
    left: 50%;
  }

}
