.ldv-dropdown {
  display: inline-block;
  padding: 0 20px;
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;
  width: 100%;

  + .ldv-dropdown::before {
    border-top: 2px solid #eee;
    content: '';
    left: 20px;
    position: absolute;
    right: 20px;
    top: 0;
  }

  &.m--disabled .ldv-dropdown__title {
    cursor: default;
    opacity: 0.3;
  }
}

.ldv-dropdown__toggle {
  display: none;

  &:checked {
    ~ .ldv-dropdown__title .chevron-down {
      transform: rotate(180deg);
    }
    ~ .ldv-dropdown__content .items {
      max-height: 50vh;
    }
  }
}

.ldv-dropdown__title {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  margin: 10px 0;
  padding-right: 20px;
  position: relative;
  user-select: none;

  a {
    display: block;
    height: 1em;
    font-size: rem(20);
    line-height: 1;
  }

  .cancel {
    margin-right: 0.4em;
  }

  .chevron-down {
    font-size: 12px;
    position: absolute;
    right: 0;
  }

  strong, span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    color: darken($blue, 6);
  }
}

.ldv-dropdown__content {

  .items {
    overflow-y: auto;
    max-height: 0;
    scrollbar-width: thin;
    transition: max-height 0.2s ease;
  }

  .item {
    display: block;
    white-space: nowrap;
    padding: 0.2em 0;

    &:first-child {
      padding-top: 8px;
    }

    &:last-child {
      padding-bottom: 8px;
    }

    a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .count {
      display: none;
    }
  }
}


@media screen and (min-width:$bp-m) {

  .ldv-dropdown {

    &:hover {
      .ldv-dropdown__content {
        display: block;
      }
    }

    + .ldv-dropdown {
      &:before {
        border-left: 1px solid $beige;
        border-top: none;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: auto;
        top: 0;
      }

      .ldv-dropdown__content {
        left: 0;
        min-width: 100%;
      }
    }

    &:last-child {
      .ldv-dropdown__content {
        left: auto;
        right: 0;
      }
    }
  }

  .ldv-dropdown__title {
    cursor: default;

    .chevron-down {
      transform: none !important;
    }

    span {
      color: inherit;
    }
  }

  .ldv-dropdown__content {
    background-color: $white;
    box-shadow: 0px 8px 16px 0px rgba($black, 0.2);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: none;
    padding-bottom: 8px;
    max-height: none !important;
    max-width: 320px;
    min-width: calc(100% - 20px);
    position: absolute;
    width: fit-content;
    z-index: 10;

    .items {
      max-height: 80vh !important;
    }

    .item {
      padding: 0.2em 1em;

      &:hover {
        background-color: rgba($black, 0.1);
      }
    }
  }

}
