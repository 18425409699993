.products-slider-with-bg {
  background-image: url('../../images/bg-items.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 0 5px;

  .block + & {
    margin-top: -40px;
  }
}

.pswb__text-cont {
  background-color: $beige-2;
  padding: 24px;
  margin: -24px;
}

.pswb__title {
  color: $blue;
  text-align: center;
}

.pswb__text {
  font-size: 18px;
  font-weight: $fw-bold;
  margin-bottom: 24px;
  opacity: 0.8;
  text-align: center;
}

.pswb__slider {
  position: relative;
  width: 70%;
  padding-bottom: 40px;
  margin: 24px 0 24px;

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    min-width: 240px;
    padding: 0 8px;
  }

  &:not(.slick-initialized) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;

    .product-teaser {
      &:nth-child(n-5) {
        margin: 0 8px;
      }
      &:nth-child(n+5) {
        display: none;
      }
    }
  }
}

.pswb__arrows {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 6;
}

@media all and (min-width: $bp-m) {
  .products-slider-with-bg {
    padding: 125px 0 65px;
  }

  .pswb__text-cont {
    max-width: 600px;
    margin: auto;
  }

  .pswb__slider {
    width: 100%;
  }

}

