.sidecart {
  background-color: $white;
  bottom: 0;
  display: block;
  height: 100%;
  max-width: 100vw;
  min-height: 80vh;
  opacity: 0;
  padding-top: 60px;
  position: fixed;
  right: 0;
  transform: translateX(100%);
  width: 100%;
  transition: padding-top 0.3s ease, transform 0.3s ease, opacity 0.2s ease;
  z-index: -1;

  &::before {
    background: $white;
    box-shadow: 0 3px 6px rgba($black, 0.16);
    content: '';
    display: block;
    height: 200px;
    margin-top: -200px;
    position: relative;
  }

  &.active {
    transform: translateX(0);
    opacity: 1;
  }

  > div {
    border-left: 8px solid $orange;
    height: 100%;
  }

  .block-minicart {
    height: 100%;
    margin: 0;
    padding: 24px;

    > div {
      height: 100%;
    }

    .block-content {
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      justify-content: space-between;
    }

    .minicart-items-wrapper {
      border: none;
      scrollbar-width: thin;
    }

    .product-item + .product-item {
      border: none;

      .product-item-details {
        &::before {
          display: block;
          content: '';
          height: 1px;
          background-color: $beige;
          position: relative;
          top: -20px;
        }
      }
    }

    .product-item-name {
      font-family: $title-font-stack;
      font-size: rem(14);
      font-weight: $fw-bold;
    }

    .product-item-pricing {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .item-qty {
        font-size: rem(16);
        font-weight: $fw-bold;
        margin-right: 0.5em;
      }

      .item-price {
        color: $gray-d;
        display: inline-block;
        font-size: rem(12);

        .price-including-tax {
          display: inline;
          font-size: inherit;
          margin: 0;
        }

        .price {
          font-weight: $fw-regular;
        }
      }

      .price-container {
        font-size: rem(18);
        font-weight: $fw-bold;
        * {margin: 0;}
      }
    }

    .subtotal {
      align-items: center;
      border-top: 1px solid $beige;
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      padding-top: 24px;

      .label {
        color: $gray-d;
        font-size: rem(12);
        font-weight: $fw-bold;
      }

      .price {
        font-size: rem(33);
        font-weight: $fw-bold;
      }
    }

    .actions {
      text-align: right;
    }
  }
}

@media screen and (min-width:$bp-l) {
  .sidecart {
    padding-top: 108px;
    width: 500px;

    .m--shrinked & {
      padding-top: 80px;
    }
  }
}
