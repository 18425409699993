.ldv-loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($white, 0.5);
  z-index: 9999;

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    width: 80px;
    height: 80px;
    margin: 8px;
    border-radius: 50%;
    border: 10px solid $blue;
    border-color: $blue transparent $blue transparent;
    animation: ldv-loader 1.2s linear infinite;
  }
}

@keyframes ldv-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

