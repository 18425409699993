/**
 * Header
 */
.page-header {
  border: none;
  margin-bottom: 0;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  box-shadow: 0 3px 6px rgba($black, 0.16);
  background-color: $white;
  top: 0;
}

.ldv-header {
  background-color: $white;
  box-shadow: 0 3px 6px rgba($black, 0.16);
  height: 60px;
  min-width: 320px;
  width: 100%;
  position: relative;

  .ldv-nav-open & {
    box-shadow: 0 3px 6px rgba($black, 0.16);
  }
}

.ldv-nav {
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  background-color: $white;
  transition: bottom 0.3s ease;
  padding-top: 60px;
  z-index: -1;
  display: flex;
  flex-direction: column;

  .ldv-nav-open & {
    bottom: 0;
  }
}

.ldv-logo {
  bottom: -8px;
  display: block;
  height: 60px;
  position: relative;
  z-index: 2;

  img {
    display: block;
    height: 100%;
    width: auto;
  }
}

.ldv-top-buttons {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
}

.ltb__button {
  opacity: 1;
  width: 36px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
  background-color: $gray-l;
  border: none;
  border-radius: 50%;
  font-size: 18px;
  padding: 0;
  position: relative;
  color: $blue;
  transition: color ease 0.3s, background-color ease 0.3s, font-size ease 0.3s, opacity 0.3s ease;

  .ldv-nav-open & {
    opacity: 0;
  }

  &:visited {
    color: $blue;
  }
  &:hover {
    color: darken($blue, 10);
    font-size: 21px;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.m--cart {
    background-color: $blue;
    color: $white;

    &:hover {
      background-color: darken($blue, 10);
    }

    &.active {
      background-color: $orange;

      &:hover {
        background-color: darken($orange, 10);
      }
    }


    .counter {
      align-items: center;
      background-color: $white;
      bottom: -5px;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba($black, 0.16);
      color: $orange;
      display: flex;
      justify-content: center;
      font-size: rem(12);
      font-weight: $fw-bold;
      position: absolute;
      height: 24px;
      right: -5px;
      width: 24px;

      &.empty {
        display: none;
      }
    }
    .counter-label {
      display: none;
    }
  }
}

.ldv-toggle-nav {
  background-color: transparent;
  border-radius: 0;
  bottom: auto;
  color: $blue-d;
  display: inline-block;
  font-size: 16px;
  height: 60px;
  left: auto;
  position: relative;
  right: auto;
  top: auto;
  transition: color 0.3s ease;
  vertical-align: middle;
  width: 60px;

  &::before {
    display: none;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity ease 0.3s;
  }

  .close {
    opacity: 0;

    .ldv-nav-open & {
      opacity: 1;
    }
  }

  .menu {
    opacity: 1;

    .ldv-nav-open & {
      opacity: 0;
    }
  }

  &:hover,
  &:focus {
    background-color: transparent;
    border: none;
    color: $blue;
    box-shadow: none;
  }
}

.ldv-search {
  border-bottom: solid 2px $beige;
  margin: 0 -32px;
  position: relative;
  width: calc(100% + 64px);
  z-index: -1;
  transition: 0.3s transform ease;

  .filter-active &, &.m--hidden {
    transform: translateY(-100%);
  }
}

.ldv-nav__buttons {
  background-color: $beige;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 0 32px;
  flex-grow: 100;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    background-color: var(--context-light);
    height: 20px;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: -1;
    transition: background-color 0.3s ease;
  }
}

.ldv-nav__button {
  display: block;
  font-size: 18px;
  padding: 19px 0;
  border-bottom: solid 1px rgba($brown,0.2);

  .svg-cont {
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    color: $blue;
    background-color: $white;
    position: relative;
    height: 36px;
    width: 36px;
    margin-right: 16px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
    }

    &.m--bg-blue {
      background-color: $blue;
      color: $white;
    }
  }

  .label {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    text-decoration: none;
  }
}

@media all and (min-width:$bp-l) {
  .ldv-header {
    box-shadow: none;
    display: flex;
    height: 108px;
    justify-content: space-between;
    align-items: center;
    transition: height 0.3s ease;

    &.m--shrinked {
      height: 80px;
    }
  }

  .ldv-logo {
    bottom: -10px;
    display: inline-block;
    height: 96px;
    min-width: 180px;
    transition: all 0.3s ease;

    .m--shrinked & {
      bottom: 0;
      height: 60px;
    }
  }

  .ldv-top-buttons {
    right: 32px;
  }

  .ltb__button {
    width: 48px;
    height: 48px;
    font-size: 22px;
    margin-left: 12px;

    &:hover {
      background-color: darken($gray-l, 10);
      font-size: 25px;
    }
  }

  .ldv-toggle-nav {
    display: none;
  }

  .ldv-nav {
    align-items: center;
    align-self: stretch;
    bottom: auto;
    display: flex;
    flex-direction: row-reverse;
    // flex-grow: 2;
    height: auto;
    max-width: 70%;
    padding: 0 20px 0 0;
    position: relative;
    width: auto;
    z-index: 0;
  }

  .ldv-nav__buttons {
    display: none;
  }

  .ldv-search {
    border: none;
    position: relative;
    display: inline-block;
    margin: 0 auto;
    max-width: 300px;
    z-index: 0;

    &.m--hidden {
      transform: none;
    }
  }

  .ldv-top-buttons {
    display: inline-block;
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    vertical-align: middle;
  }

  .ldv-navigation__menu {
    // position: fixed;
    // top: 100%;
  }

}

