.mpblog-post-index {
  .page-main > .columns {
    @extend .l-wrapper-large;
  }

  .post-list-item {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 2em;
    gap: 1em;
  }

  .post-date {
    background-color: $gray-l;
    border: 1px solid $beige;
    border-radius: 2em;
    color: $blue-d;
    display: inline-block;
    font-size: rem(12);
    font-weight: $fw-bold;
    line-height: 1;
    left: 0;
    padding: 0.75em 1.5em;
    text-transform: uppercase;
    width: auto;
  }

  .post-title {
    color: $blue;
    font-family: $title-font-stack;
    font-weight: $fw-bold;
    font-size: rem(33);
    margin-bottom: 0.5em;
  }

  .post-content {
    p + p {
      margin-top: 1em;
    }
  }

  .post-featured {
    order: 1;
    width: 100%;

    .img-responsive {
      border-radius: 8px;
    }
  }

}


// Instagram
.instagram-media {
  background: #FFF;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  min-height: 407px;
  margin: 0 0 44px;
  min-width: 326px;
  max-width: 366px;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.instagram-media__fallback {
  border-radius: 3px;
  content: url('../../images/media/instagram-post.png');
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
}

iframe + blockquote {
  .instagram-media__fallback {
    display: none;
  }
}


// Youtube
.video-media {
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    display: none;
  }
}

.video-media__thumbnail {
  left: 0;
  height: (4/3 * 100%);
  position: absolute;
  top: (-1/6 * 100%);
  width: 100%;
}

.video-media__play {
  align-items: center;
  background-color: rgba($blue-d, 0.6);
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  font-size: rem(16);
  justify-content: center;
  left: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  transition: background-color 0.2s ease;

  svg {
    color: rgba(#fff, 0.4);
    font-size: rem(77);
  }

  span {
    font-family: $title-font-stack;
    font-weight: $fw-bold;
    margin-top: -0.8em;
    transition: transform 0.2s ease;
  }

  &:hover {
    background-color: rgba($blue-d, 0.7);
    color: #fff;

    span {
      transform: scale(1.05);
    }
  }
}

@media all and (min-width: $bp-m) {
  .mpblog-post-index {

    .post-list-item {
      border-left: 1px solid $beige;
      flex-flow: row nowrap;
      gap: 0;
      justify-content: space-between;
      margin-bottom: 0;
      margin-left: 80px;
      padding: 80px 0 40px;
      position: relative;

      &:nth-child(odd) {
        flex-direction: row-reverse;
      }

      &:last-child {
        margin-bottom: -0.5em;
      }
    }

    .post-date {
      left: 0;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
    }

    .post-content {
      max-width: 88%;
      padding-left: 150px;
      margin: 0 auto 0 -40px;
    }

    .post-featured {
      margin-left: -40px;
      order: initial;
      width: 33.4545%;

      + .post-content {
        margin-left: -50px;
        max-width: 58%;
      }
    }

  }
}
