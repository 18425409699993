.minisearch {
  position: relative;

  .input-text {
    border-width: 0;
    border-radius: 0;
    padding-left: 20px;
    padding-right: 56px;
    width: 100%;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .actions button {
    background-color: $beige;
    border-radius: 50%;
    color: $white;
    font-size: 16px;
    height: 36px;
    margin-top: -18px;
    position: absolute;
    right: 6px;
    top: 50%;
    width: 36px;
    transition: color 0.3s ease, background-color 0.3s ease, font-size 0.3s ease, opacity 0.3s ease;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      background-color: darken($beige, 10);
      cursor: pointer;
      font-size: 18px;
    }
  }

}

.searchsuite-autocomplete {
  left: 8px;

  .title {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
  }

  li {
    padding: 0;
    font-size: 13px;

    a {
      display: flex;
      flex-flow: row nowrap;
      padding: 6px 10px;

      & > div {
        float: none;
        width: auto;
      }

      &:hover {
        background-color: #f8f8f8;
      }
    }
  }

  .suggest {
    ul {
      text-align: center;
    }
    li {
      display: inline-block;

      a {
        padding: 5px;
      }
    }
  }

  .product {

    .qs-option-image {
      width: 64px;
      flex-shrink: 0;

      img {
        display: block;
        object-fit: contain;
        height: auto;
        max-height: 100px;
      }
    }

    .qs-option-info {
      margin-bottom: 0;
    }
  }
}



@media screen and (min-width:$bp-l) {
  .minisearch {
    .input-text {
      border-width: 2px;
      border-radius: 24px;
      transition: border-color 0.3s ease;

      &:focus {
        box-shadow: 0 3px 6px rgba($black, 0.16);
      }
    }

    .actions button {
      color: $white;
    }

    &:focus-within {
      .input-text {
        border-color: $orange;
      }

      .actions button {
        background-color: $orange;
      }
    }
  }
}
