/**
 * ==================== @ TAC theme
 */

 div#tareaucitronRoot {
  font-family: Arial, Verdana, sans-serif !important;
}

#tarteaucitron * {
  line-height: 1 !important;
}

div#tarteaucitronRoot.tarteaucitronBeforeVisible::before {
  background-color: rgba(0,0,0,.75) !important;
  opacity: 1 !important;
  z-index: 999999 !important;
}

div#tarteaucitronRoot div#tarteaucitronAlertBig {
  background-color: #fff !important;
  border-radius: 2px !important;
  box-shadow: 0 1px 3px rgba(0,0,0,.75) !important;
  box-sizing: border-box !important;
  color: #000 !important;
  max-width: 770px;
  max-height: 95vh;
  padding: 0 !important;
  overflow-y: auto;
}

div#tarteaucitronAlertBig::before {
  content: '' !important;
  background: url(../../images/logo.png) no-repeat center center / contain;
  display: block;
  margin: 30px auto 0;
  max-width: 250px;
  height: 136px;
}

div#tarteaucitronAlertBig::after {
  content: '';
  display: block;
  padding-bottom: 15px !important;
}

#tarteaucitronDisclaimerAlert {
  color: #000 !important;
  display: block !important;
  font-size: 14px !important;
  line-height: 20px !important;
  max-width: 600px !important;
  margin: 30px auto 25px !important;
  text-align: justify !important;
}

#tarteaucitronDisclaimerAlert h2 {
  color: #000 !important;
  font-size: 34px !important;
  font-weight: 700 !important;
  line-height: 38px !important;
  margin-bottom: 30px !important;
  padding: 0 1em !important;
  text-align: center !important;
}

#tarteaucitronDisclaimerAlert h2:before {
  display: none;
}

#tarteaucitronAlertBig button#tarteaucitronPersonalize2, #tarteaucitronAlertBig button.tarteaucitronDeny {
  border: 2px solid transparent !important;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000 !important;
  cursor: pointer !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  height: 55px !important;
  letter-spacing: 1px !important;
  line-height: 34px !important;
  // margin: 7px !important;
  margin: 7px auto;
  max-width: 300px !important;
  padding: 0 13px !important;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color .2s ease-in-out;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
}

#tarteaucitronAlertBig button#tarteaucitronPersonalize2 {
  background-color: lighten($blue, 10) !important;
  border-color: lighten($blue, 10) !important;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,.2);
}

#tarteaucitronAlertBig button#tarteaucitronPersonalize2:hover {
  background-color: #fff !important;
}

#tarteaucitronRoot .tarteaucitronCheck::before, #tarteaucitronRoot .tarteaucitronCross::before {
  color: inherit !important;
}

#tarteaucitronAlertBig button.tarteaucitronDeny {
  background-color: #fff !important;
  border-color: #000 !important;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  color: #888 !important;
  padding: 8px !important;
  // margin: 8px !important;
  margin: 8px auto !important;
}

#tarteaucitronAlertBig #tarteaucitronCloseAlert:hover, #tarteaucitronAlertBig #tarteaucitronPrivacyUrl:hover {
  color: #444 !important;
  text-decoration: underline;
}

#tarteaucitronAlertBig a {
  color: $blue-d !important;
}

#tarteaucitron div#tarteaucitronInfo {
  font-size: 0.8em !important;
  font-weight: normal !important;
  line-height: 1.2 !important;
}

#tarteaucitron #tarteaucitronCookieUrl, #tarteaucitron #tarteaucitronCookieUrlDialog {
  background: #fff;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 5px 10px;
  font: 16px verdana;
}

#tarteaucitron #tarteaucitronCookieUrlDialog ~ br {
  display: none;
}

#tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
  display: none !important;
}

#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  position: static !important;
  max-width: 100% !important;
  display: block !important;
}

div#tarteaucitronAlertBig button {
  margin: 0 auto 10px!important;
}

