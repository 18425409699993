/**
 * ==================== @ FORMS STYLES
 */

.fieldset {
  .legend {
    font-size: 18px;
    font-weight: $fw-bold;
    margin-bottom: 24px;
    color: $blue-d;
  }

  > .field {
    margin-bottom: 15px;
  }

  > .field:not(.choice) {
    > .label, > .control {
      float: none;
    }
  }
}

.field {
  .label {
    font-size: 12px !important;
    font-weight: $fw-bold;
    color: $gray-d;
  }

  &.required {
    .label {
      &::after {
        font-size: 12px !important;
        content: " *";
      }

    }

  }

}

.input-text {
  border: solid 2px $beige;
  border-radius: 24px;
  line-height: 22px;
  padding: 11px 16px;

  &::placeholder {
    color: $gray-d;
    opacity: 0.6;
  }

}

.field.choice {
  .checkbox {
    display: none;

    &:checked {
      + label::after {
        opacity: 1;
      }

    }

    + .label {
      font-weight: $fw-regular;
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 1px;
        width: 16px;
        height: 16px;
        border: solid 2px #bdbdbd;
        border-radius: 2px;
      }

      &::after {
        content: '';
        display: block;
        background-color: #696969;
        width: 8px;
        height: 8px;
        top: 5px;
        left: 4px;
        position: absolute;
        opacity: 0;
        transition: opacity 0.2s ease;
      }

    }

  }

}

select {
  appearance: none;
  background: svg-url($svg-select-arrow, $gray-d) no-repeat right 0.8em center / 1em;
  cursor: pointer;
  padding-right: 4em;

  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
}
