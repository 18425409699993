.catalog-product-view {
  .product-info-main {
    @extend .l-wrapper;
    float: none;
    margin-top: 10px;
    width: 100%;
  }

  .product-info-right {
    background-color: $gray-l;
    margin: 0 -32px;
    padding: 32px;

    .price-wrapper .price {
      font-size: rem(33);
    }
  }

  .stock {
    display: inline-block;
    font-weight: 700;
    margin-right: 20px;
    text-transform: uppercase;
    vertical-align: top;

    span {
      text-transform: none;
    }

    &.available {
      color: $green;
    }
    &.unavailable {
      color: $orange;
    }
    &.forthcoming {
      color: $blue-d;
    }
    &.custom {
      color: $blue;
    }

    &::before {
      background-color: currentColor;
      border-radius: 100%;
      content: '';
      display: inline-block;
      height: 10px;
      margin-right: 0.2em;
      width: 10px;
    }
  }

  .ask-date {
    align-items: center;
    display: flex;
    color: $black;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    width: calc(100% - 130px);

    &::before {
      align-items: center;
      background-color: $blue;
      border-radius: 50%;
      color: $white;
      content: 'i';
      display: inline-flex;
      font-family: $title-font-stack;
      font-size: 22px;
      font-weight: $fw-bold;
      flex-shrink: 0;
      height: 30px;
      justify-content: center;
      margin-right: 0.5em;
      padding-top: 3px;
      width: 30px;
      vertical-align: middle;
    }

    &:hover {
      &::before {
        // background-color: $blue-d;
      }

      span {
        text-decoration: underline;
      }
    }
  }

  .shipping-delay {
    font-size: 12px;
    font-style: italic;
  }

  .product-title {
    .base {
      color: $blue-d;
      font-size: rem(24);
    }
  }

  .product-social-links {
    display: none;
  }

  .product-cover {
    margin-bottom: 1.5em;

    > div {
      @extend %product-image;
    }

    a {
      color: $blue-d;
      display: inline-block;
      font-size: rem(12);
      font-weight: $fw-bold;
      margin-top: 0.5em;
      transition: color 0.2s ease;

      &:hover {
        color: $orange-d;
      }

      .icon {
        font-size: 1.7em;
        margin: 0 0.2em;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        text-decoration: underline;
        vertical-align: middle;
      }
    }
  }

  .additional-attributes-wrapper .additional-attributes > tbody > tr {
    display: block !important;

    > .label {
      display: inline !important;
      color: $brown;
      font-size: rem(12);
      font-weight: $fw-regular;
      padding: 0;

      &::after {
        content: ' : ';
      }
    }
    > .data {
      display: inline !important;
      color: $blue-d;
      font-size: rem(12);
      font-weight: $fw-bold;
      padding: 0;
    }
  }

  .product.attribute.overview {
    color: $dark-l;
    font-size: rem(18);
    font-style: italic;
  }

  .product-add-form {
    margin-top: -60px;

    .fieldset {
      margin: 0;
    }

    .box-tocart {
      display: block;
      margin: 0;

      .field.qty, .actions {
        display: block;
        margin: 15px 0 0;
        padding: 0;
        text-align: right;
      }

      input.qty {
        height: 46px;
        min-width: 64px;
        margin-left: 0.5em;
      }
    }

    button.tocart {
      font-size: rem(16);
      width: 220px;
    }
  }

  .ldv-footer-bottom {
    padding-bottom: 170px;
  }
}

.product-knowmore {
  display: none;
}

.product-details {
  margin: 32px 0 64px;
  .small-title {
    font-size: 12px;
    margin: 8px 0;
  }
}

.product-details__title {
  color: $blue-d;
  font-size: 24px;
  text-align: center;
}

.product-details__text {
  color: $dark-l;
  font-style: italic;

  p + p {
    margin-top: 0.5em;
  }

  br {
    margin-bottom: 0.25em;
  }
}

.product-details__map {
  background-color: #ddd;
  font-style: normal;
  height: 50vw;
  margin-bottom: 1em;

  .leaflet-marker-icon {
    cursor: default;
  }
}



.product-dimensions {
  background-color: $blue-d;
  color: $white;
  padding: 32px 0 5px;

}

.product-dimensions__inner {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
}

.product-dimensions__item {
  width: 50%;
  padding: 0 15px;
  text-align: center;
  position: relative;
  margin-bottom: 24px;
  &::before {
    content: '';
    position: absolute;
    bottom: 15%;
    left: 0;
    background-color: $white;
    height: 42px;
    width: 1px;
    display: block;
  }

  &:nth-child(2n+1) {
    &::before {
      display: none;
    }

  }

}

.product-dimensions__item-inner {
  max-width: 150px;
  margin: auto;
}

.product-dimensions__icon {
  font-size: 45px;
  svg {
    display: inline-block;
  }

}

.product-dimensions__name {
  font-size: 12px;
}

.product-dimensions__value {
  font-weight: $fw-bold;
}


.product-preview {
  margin: 32px 0;
  position: relative;
  text-align: center;
}

.product-preview__inner {
  max-width: 800px;
  margin: auto;
  position: relative;
}

.product-preview__item {
  display: inline-block;
  height: 100%;
  padding: 0 16px;
  margin: 0 -6px;
  vertical-align: top;

  .slick-slider & {
    margin: 0;
  }

  a {
    display: block;
    line-height: 1;
  }
}

.product-preview__item-inner {
  border-radius: 8px;
  box-shadow: 0 0px 2px rgba($black, 0.05);
  margin: auto;
  max-width: 175px;

  img {
    display: block;
  }
}

.product-cover__preview, .product-preview__item-inner {
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue-d;
    opacity: 0;
    transition: opacity ease 0.3s;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    font-size: 22px;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    width: 64px;
    height: 64px;
    display: block;
    z-index: 2;
    @include background-svg($svg-search, $blue);
    background-position: center center;
    background-size: 22px;
    background-color: $white;
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
    transition: opacity ease 0.3s, transform ease 0.3s;
  }

  &:hover {
    &::before {
      opacity: 0.8;
    }

    &::after {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.product-preview__slider {
  padding-bottom: 40px;
}

.product-preview__arrows {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 6;
}

.product-preview__dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 5;
}

.product-others {
  background-color: $gray-l;
  padding-top: 64px;

  .ps__slider {
    margin-top: 0;
  }
}

.ldv-bottom-bar {
  background-color: $gray-l;
  border-top: 1px solid $beige;
  bottom: 0;
  overflow: hidden;
  padding: 10px 0 60px;
  position: fixed;
  width: 100%;
  z-index: 100;
  transform: translateY(100%);
  transition: transform 0.3s ease;

  &.m--visible {
    transform: translateY(0);
  }

  .product-title {
    color: $blue-d;
    display: none;
    font-family: $title-font-stack;
    font-size: 16px;
    font-weight: $fw-bold;
  }

  .price-box {
    .weee {
      font-weight: $fw-bold;
      margin: 0;
      line-height: 46px;
      .price {
        font-size: rem(33);
      }
    }
  }

  .product-addtocart {

    .box-tocart {
      margin: 0;
    }

    .fieldset {
      margin: 0;
    }

    .field.qty {
      margin: 0 0 20px;
      padding: 0;
      text-align: right;
    }

    .input-text.qty {
      height: 46px;
      width: 64px;
    }

    .actions {
      position: absolute;
      right: 0;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}

.ldv-bottom-bar__inner {
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  position: relative;
}

@media all and (min-width: $bp-m) {
  .catalog-product-view {
    .ldv-footer-bottom {
      padding-bottom: 100px;
    }
  }

  .ldv-bottom-bar {
    overflow: hidden;
    padding: 9px 0;

    .product-title {
      display: block;
      flex-shrink: 0;
      height: 3.25em;
      max-width: 33%;
      min-width: 260px;
      line-height: 3.25em;
      position: relative;
      padding-right: 16px;

      &::before {
        background-color: $white;
        content: '';
        height: 300%;
        position: absolute;
        right: 0;
        top: -50%;
        width: 50vw;
        z-index: -1;
      }
    }
    .product-title__inner {
      display: inline-block;
      overflow: hidden;
      line-height: 1.5;
      max-height: 3.25em;
      vertical-align: middle;
    }

    .price-box {
      margin-left: 32px;
      margin-right: auto;
    }

    .product-addtocart {
      flex-shrink: 0;

      .fieldset {
        align-items: flex-end;
        display: flex;
        flex-flow: row nowrap;
      }

      .field.qty {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        margin: 0 1em 0 0;

        .label {
          margin-right: 1em;
        }
      }

      .actions {
        position: static;
        padding: 0;
        flex-grow: 0;

        button {
          width: 220px;
        }
      }
    }
  }

  .ldv-bottom-bar__inner {
    align-items: center;
  }

}

@media all and (min-width: $bp-l) {
  .catalog-product-view {
    .product-info-main {
      padding-left: 362px;
      min-height: 400px;
    }

    .product-info {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    .product-info-left {
      padding-right: 32px;
    }

    .product-info-right {
      align-self: center;
      background: none;
      border-left: 1px solid $beige;
      margin: 0;
      min-width: 280px;
      padding: 8px 0 8px 32px;
    }

    .ask-date {
      width: 100%;
    }

    .product-title {
      .base {
        font-size: rem(33);
      }
    }

    .product-cover {
      left: 32px;
      margin-bottom: 0;
      position: absolute;
      top: 0;
      width: 310px;

      > div {
        justify-content: center;
      }
    }

    .product.attribute.overview {
      margin-bottom: 0;
    }

    .product-add-form {
      margin-top: 0;

      .box-tocart {
        .field.qty, .actions {
          text-align: left;
        }

        button {
          white-space: nowrap;
        }
      }
    }
  }

  .product-knowmore {
    display: block;
    font-family: $title-font-stack;
    font-size: 12px;
    font-weight: $fw-bold;
    margin: rem(42) 0;
    text-align: center;

    a {
      color: $blue-d;
      display: inline-block;
      transition: color 0.2s ease;

      &:hover {
        color: $orange-d;

        svg {
          transform: rotate(90deg) translateX(0.2em);
        }
      }
    }

    span {
      display: block;
    }

    .icon {
      font-size: 1.5em;
      transform: rotate(90deg);
      transition: transform 0.2s ease;
    }
  }

  .product-details {
    background-color: $gray-l;
    border-top: 1px solid $beige;
    padding: 64px 0;
    margin: 0;
  }

  .product-details__text {
    &.m--with-map {
      display: flex;
      flex-flow: row-reverse nowrap;
    }
  }

  .product-details__map-container {
    flex-shrink: 0;
    margin-left: 64px;
    min-height: 275px;
    min-width: 300px;
    position: relative;
    width: 28.680688%;
    z-index: 99;
  }

  .product-details__map {
    height: 100%;
    margin-bottom: 0;
    min-width: 100%;
    width: calc(50vw - 80%);
  }

  .product-dimensions {
    padding: 64px 0 40px;
  }

  .product-dimensions__item {
    width: 30%;
    &:nth-child(2n+1) {
      &::before {
        display: block;
      }

    }

    &:nth-child(3n+1) {
      &::before {
        display: none;
      }

    }

  }

  .product-preview {
    margin: 64px 0 24px;
  }

}
