.highlight {
  position: relative;
  z-index: 2;
}

.highlight__bg {
  min-height: 280px;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -1;
}

.highlight__text-container {
  padding: 20px 35px 75px;

  p {
    font-size: 18px;
  }
}

.highlight__title {
  text-align: center;
}

.highlight__cover {
  display: none;
}

.highlight__btn {
  background-color: $orange;
  display: block;
  position: absolute;
  bottom: -27px;
  right: 0;
  width: 200px;
  color: $white !important;
  font-size: 18px;
  font-family: $title-font-stack;
  height: 75px;
  padding: 20px 0 0 46px;
  text-align: left;
  border-top-left-radius: 64px;
  transition: background-color ease 0.3s;

  svg {
    position: absolute;
    display: block;
    top: 31px;
    left: 111px;
    font-size: 40px;
    transform: rotate(8deg);
  }

  &:hover {
    background-color: $blue-d;
  }
}

@media all and (min-width:$bp-m) {

  .highlight {
    padding: 190px 0 25px;
  }

  .highlight__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 0;
  }

  .highlight__text-container {
    width: 832px;
    max-width: calc(100% - 60px);
    margin: auto;
    position: relative;
    padding: 0;
  }

  .highlight__text {
    background-color: $white;
    width: 70%;
    padding: 67px 225px 67px 54px;
    position: relative;
    z-index: 2;
    border-bottom-right-radius: 64px;
  }

  .highlight__cover {
    display: block;
    position: absolute;
    right: 15%;
    top: -26px;
    width: 40%;
    z-index: 5;
  }

  .highlight__btn {
    width: 50%;
    height: 220px;
    border-top-right-radius: 64px;
    bottom: -52px;

    span {
      display: block;
      position: absolute;
      top: 50%;
      right: 20%;
      padding: 0 30px 19px 0;

      svg {
        right: 0;
        bottom: 0;
        left: auto;
        top: auto;
      }
    }
  }
}
