/**
 * ==================== @ BUTTONS
 */

.btn {
  color: $white !important;
  display: inline-block;
  background-color: $orange;
  border: none;
  font-weight: $fw-bold;
  padding: 10px 20px;
  border-radius: 23px;
  position: relative;
  transition: background-color ease 0.3s;

  .icon {
    font-size: 1.1em;
    margin-right: 0.2em;
    position: relative;
    top: 0.1em;
  }

  &:hover, &:active, &:focus {
    background-color: $blue-d;
    border: none;
    color: $white;
  }

  &.m--arrow {
    padding-right: 56px;

    &::after {
      position: absolute;
      content: "";
      display: block;
      right: 24px;
      top: 50%;
      @include background-svg($svg-arrow, $white);
      width: 17px;
      height: 17px;
      transform: translate(0, -50%);
    }
  }

  &.m--bg-blue {
    background-color: $blue-d;

    &:hover {
      background-color: $orange;
    }
  }

  &.m--load-more {
    font-weight: $fw-regular;
    margin-top: 0.5em;

    &[disabled] {
      opacity: 0.8;
    }
  }

  &.is-loading {
    color: transparent;

    &::before {
      content: '';
      background: url('../../images/btn-loader.svg') no-repeat center/contain;
      position: absolute;
      height: 1.2em;
      left: 50%;
      width: 75%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.btn-centered {
  text-align: center;
}

.btn-round {
  background-color: $white;
  color: $blue;
  box-shadow: none;
  border-radius: 50%;
  height: 1em;
  padding: 0;
  position: relative;
  width: 1em;

  svg {
    font-size: 0.444em;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover, &:focus, &:active {
    box-shadow: none;
  }
}

.action.primary, .action.secondary {
  color: $white;
  border-radius: 23px;
  font-weight: $fw-bold;
  padding: 10px 20px;
  min-width: 170px;
  transition: color ease 0.3s, background-color ease 0.3s;
}

.action.secondary {
  border: 2px solid $orange;
  &:not(:hover) {
    color: $orange;
    background-color: $white;
  }
}
