/**
 * Fonts
 */

@mixin fontface($name, $path, $weight: null, $style: null, $exts:woff2 woff) {
  $src: null;
  @each $ext in $exts {
    $src: append($src, url(quote($path + "." + $ext+"?v=1")), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }

}

/* Eczar */
@include fontface("Eczar", "../../fonts/eczar-regular", $fw-regular, normal);
@include fontface("Eczar", "../../fonts/eczar-700", $fw-bold, normal);
@include fontface("Eczar", "../../fonts/eczar-500", $fw-medium, normal);
/* Poppins */
@include fontface("Poppins", "../../fonts/poppins-italic", $fw-regular, italic);
@include fontface("Poppins", "../../fonts/poppins-regular", $fw-regular, normal);
@include fontface("Poppins", "../../fonts/poppins-700", $fw-bold, normal);
@include fontface("Poppins", "../../fonts/poppins-700italic", $fw-bold, italic);
