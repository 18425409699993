.text-on-image {
  position: relative;
  color: $white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 64px 0;
  &::before {
    content: '';
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $blue-d;
    opacity: 0.6;
    z-index: 1;
  }

}

.text-on-image__inner {
  position: relative;
  z-index: 2;
  max-width: 350px;
  margin: auto;
  .h2-like {
    color: $white;
  }

}

@media all and (min-width:$bp-m) {
  .text-on-image {
    text-align: right;
    padding-right: 100px;
  }

  .text-on-image__inner {
    width: 50%;
    display: inline-block;
    text-align: initial;
  }

}

