/**
 * Typo
 */

.typo, [data-content-type="row"] {
  font-size: rem($default-font-size);
  line-height: 1.5;

  h2 { @extend .h2-like; }
  h3 { @extend .h3-like; }
  h4 { @extend .h4-like; }

  h2, h3, h4 {
    margin-top: 1.5em;
  }

  p {
    margin-top: 1.5em;
  }

  a:not(.btn) {
    // color: var(--alt-color, $orange);
    font-weight: $fw-bold;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
      //   color: $green-d;
    }
  }

  ul {
    li {
      padding-left: 15px;
      position: relative;

      &::before {
        background-color: $dark;
        border-radius: 8px;
        content: '';
        height: 6px;
        left: 0;
        position: absolute;
        top: calc(1em - 6px);
        width: 6px;
      }
    }
  }

  img {
    display: inline-block;
  }

  .align-center {
    .media-image {
      text-align: center;
    }
  }

  .grid-3cols,
  .grid-2cols {
    margin: 15px -10px;

    .grid-col1 {
      > :first-child {
        margin-top: 0;
      }
    }

    .grid-col1,
    .grid-col2,
    .grid-col3 {
      padding: 0 10px;
    }

  }

}

[data-content-type="row"] {

  &[data-appearance="contained"] {
    [data-element="inner"] {
      padding: rem(48) 0 rem(64);
    }
  }

  [data-content-type="heading"] {
    padding: 0 32px;

    &:first-child {
      margin-top: 0;
    }
  }

  [data-content-type="text"] {
    padding: 10px 32px;

    *:first-child {
      margin-top: 0;
    }
  }

  [data-content-type="image"][data-appearance="full-width"] {
    padding: 0 10px;

    img {
      width: 100%;
    }
  }

  .pagebuilder-column-group + .pagebuilder-column-group {
    margin-top: 15px;
  }

  .justify-space-between {
    justify-content: space-between !important;
  }

}
