.page-layout-category-full-width {
  background-color: $gray-l;

  .page-main > .columns {
    @extend .l-wrapper;
  }

  .toolbar-products {
    padding: 0;
    margin-bottom: 32px;
  }

  .modes {
    float: right;
    line-height: 28px;
    margin: 0 0 0 16px;
    padding-top: 3px;
  }

  .sorter-options, .mode {
    color: $dark-l;
    cursor: pointer;
    font-size: rem(12);
    height: 28px;
    padding: 0 10px;

    svg {
      margin-left: 0.25em;
      vertical-align: middle;
    }
  }

  .sorter-options {
    padding-right: 18px;
    background-position: right 0.4em center;
  }

  .mode {
    background-color: $white;
    border: 2px solid $beige;
    border-radius: 24px;
    display: inline-block;
    line-height: 24px;
    vertical-align: middle;

    &:hover {
      color: #333333;
    }
  }

  .filter {
    background-color: $gray-l;
    bottom: 0;
    color: $blue-d;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 30px 30px 0;
    position: fixed;
    scrollbar-width: thin;
    top: 60px;
    width: 100%;
    z-index: 100;
    transform: translateX(-100%);
    transition: 0.6s ease transform;
  }
  &.filter-active {
    height: 100vh;
    overflow: hidden;

    .filter {
      transform: translateX(0);
    }
  }

  .filter-title {
    position: relative;

    button {
      background-color: $gray-l;
      color: $blue-d;
      font-size: 36px;
      position: absolute;
      top: -5px;
      right: 0;
    }
  }

  .filter-options-title {
    font-family: $title-font-stack;
    font-size: rem(16);
    font-weight: $fw-medium;
  }
  .filter-options-content {
    font-size: rem(12);

    .item {
      margin-left: -0.5em;
      margin-bottom: 0.2em;
      overflow: hidden;
      padding-bottom: 0.2em;
      text-overflow: ellipsis;
      white-space: nowrap;

      [type=checkbox] {
        appearance: none;
        background-color: $white;
        border: 1px solid $beige;
        border-radius: 4px;
        display: inline-block;
        height: 16px;
        margin-top: 0;
        margin-left: 0.5em;
        vertical-align: top;
        width: 16px;

        &:checked {
          background-color: $green;
          border-color: $green;

          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 2px;
            top: 2px;
            width: 11px;
            height: 11px;
            @include background-svg($svg-check, $white);
          }
        }
      }

      a {
        background-color: $gray-l;
        display: inline !important;
        margin: -4px;
        padding: 8px 4px 2px;
        vertical-align: top;
      }

      .count {
        display: inline-flex;
      }

      &.selected {
        font-weight: $fw-bold;
      }

      &:hover {
        width: 200%;
      }
    }
  }

  .toolbar-amount {
    color: $blue-d;
    float: none;
    font-size: rem(12);
    font-weight: $fw-bold;
    padding: 0;
  }

}

.catalog-category-view {

  .columns .column.main {
    padding: 0;
  }

  .category-description {
    background-color: $white;
    margin: 0;
  }
}

.ldv-catalog-total {
  color: $blue-d;
  font-family: $title-font-stack;
  font-size: 16px;
  font-weight: $fw-bold;
  margin: -1em 0 0.5em;
  text-align: center;
}


@media screen and (min-width:$bp-m) {

  .page-layout-category-full-width {

    .sidebar {
      position: relative;
      z-index: 1;
    }

    .filter {
      display: block;
      overflow: visible;
      padding: 40px 0 0;
      position: static;
      transform: none;
    }

    .filter-title {
      display: none;
    }

  }

  .ldv-catalog-total {
    font-size: 16px;
    line-height: 36px;
    margin: 0;
    text-align: left;
  }

}
