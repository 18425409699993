.slick-arrow {
  width: 45px;
  height: 45px;
  display: inline-block;
  border-radius: 100%;
  position: relative;
  background-color: $beige;
  transition: background-color 0.3s ease;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    height: 45%;
    display: block;
    @include background-svg($svg-arrow, $brown);
  }

  &.arrow-prev {
    margin-right: 5px;
    &::before {
      transform: rotate(180deg) translate(50%, 50%);
    }

  }

  &:active, &:focus {
    background-color: $beige;
  }

  &.slick-disabled {
    background-color: $beige !important;
    opacity: 0.2;
    cursor: default;
  }

}

.slick-dots {
  display: inline-block;
  text-align: center;
  li {
    display: inline-block;
    font-size: 0;
    border-radius: 100%;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    margin: 0 7px;
    background-color: $beige;
    cursor: pointer;
    &.slick-active {
      background-color: $brown;
      width: 12px;
      height: 12px;
      margin: 0 4px;
    }

    &:only-child {
      display: none;
    }

    button {
      margin: 0;
      padding: 0;
    }

  }

}

@media (hover: hover) {
  .slick-arrow {
    &:hover:not(.slick-disabled) {
      background-color: $blue-d;
      &::before {
        @include background-svg($svg-arrow, $white);
      }

    }

  }

}

