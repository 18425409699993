/**
 * Breadcrumb
*/

.ldv-breadcrumbs {
  background: linear-gradient(to bottom, rgba(#18181A,0.5) 0,rgba(#18181A,0) 5em);
  color: $white;
  font-size: 12px;
  min-height: 5em;
  padding-top: 1em;
  position: absolute;
  width: 100%;
  z-index: 1;

  .breadcrumbs {
    .items {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .item {
      display: inline;

      a {
        display: inline-block;
        text-decoration: underline;

        &:hover {
          text-decoration-thickness: 2px;
        }
      }

      &:nth-child(n+2)::before {
        content: ' / ';
        display: inline-block;
        font-weight: $fw-bold;
        margin: 0 0.2em;
        vertical-align: middle;
      }

      &:last-child:not(:first-child) {
        a, strong {
          cursor: default;
          font-weight: $fw-bold;
          text-decoration: none;
        }

        &::before {
          @include background-svg($svg-mini-arrow, $white);
          content: '';
          height: 1em;
          position: relative;
          top: -1px;
          width: 1.5em;
        }
      }
    }
  }

  &.m--blue {
    background: none;
    color: $blue-d;
    position: relative;

    .breadcrumbs {
      .item {
        &:last-child:not(:first-child)::before {
          @include background-svg($svg-mini-arrow, $blue-d);
        }
      }
    }
  }
}

