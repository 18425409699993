/**
 * ==================== @ SASS PLACEHOLDERS
 */

%clearfix {
  &::before, &::after { content: " "; display: table; }

  &::after { clear: both; }

}

%ghost-valign {
  white-space: nowrap;

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

}

%ghost-valign-inner {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%product-image {
  display: flex;
  position: relative;

  span {
    border-radius: 8px;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    position: relative;

    img {
      display: block;
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
    }

    &.m--cover {
      &::before {
        content: '';
        background: linear-gradient(to bottom right, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.35) 100%);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
      }

      &::after {
        content: '';
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.4) 25%, rgba(255, 255, 255, 0) 100%);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 16px;
      }
    }

    &.m--noeffect {
      border-radius: 0;
      &::before, &::after {
        display: none;
      }
    }
  }
}
