/**
 * ==================== @ GENERIC FLOW TOOLS
 */
.main {
  position: relative;
}

.l-wrapper {
  margin: 0 auto;
  max-width: $layout-width;
  padding: 0 32px;
  position: relative;
}

.l-wrapper-large {
  margin: 0 auto;
  max-width: $layout-width-l;
  padding: 0 32px;
  position: relative;
}

.l-scrollbar {
  position: relative;
  overflow: hidden;
}

.l-grid {
  margin: 0 -10px;

  .grid__item {
    padding: 10px;
    &.m--same-height {
      height: 100%;
    }

  }

}

.is-semantic {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  display: block;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.sv-g {
  font: inherit;
}

.dialog-off-canvas-main-canvas {
  overflow: hidden;
}

.page-main {
  padding-top: 110px;
}

.page-main ~ .page-main {
  padding-top: 0;
}

.page.messages {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 9;

  .messages {
    display: inline-block;
  }

  .message {
    border-radius: 8px;
    margin: 2px 0;
    opacity: 0.95;
  }

  .ldv-breadcrumbs + & {
    margin-top: 40px;
  }

  .ldv-breadcrumbs.m--blue + & {
    position: relative;
    margin-top: -20px;
    min-height: 25px;
  }
}

.page-wrapper {
  overflow: hidden;
}

.modal-popup {
  max-width: 100%;

  &.modal-slide {
    max-width: calc(100% - 44px);
  }
}

@media all and (min-width:$bp-m) {
  .modal-popup {
    &.modal-slide {
      max-width: 100%;
    }
  }
}

@media all and (min-width:$bp-l) {
  .page-main {
    padding-top: 108px;
  }
}

