/**
* Menu
*/
.navigation {
  background-color: $white;
  .level0 {
    font-family: $title-font-stack;
  }

}

.nav-open {
  .nav-toggle::after {
    display: none;
  }

  .page-wrapper {
    left: 0;
  }

}

.ldv-navigation {
  overflow-y: scroll;
  padding: 0;
  background-color: var(--context-light);
  color: var(--context-text);
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;

  a {
    &:hover,
    &:visited,
    &:active {
      color: var(--context-text);
      text-decoration: none;
    }
  }

  ul {
    margin: 0;
  }

  .category-item.level0 {
    margin: 0;
    position: relative;

    &::after {
      background-color: $brown-2;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 32px;
      position: absolute;
      width: calc(100% - 64px);
    }

    &:last-child::after {
      display: none;
    }

    &:first-child > a {
      padding-top: 20px;
    }
  }

  .level-top {
    & > a {
      font-size: 24px;
      font-weight: $fw-medium;
      font-family: $title-font-stack;
      position: relative;
      padding: 13px 52px 13px 32px;
      display: block;
      transition: color 0.3s ease;
      &::after {
        content: '';
        display: block;
        @include background-svg($svg-more, $brown-2);
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translate(0,-50%);
        width: 16px;
        height: 16px;
      }

      &:hover,
      &:visited,
      &:active {
        color: var(--context-text);
        text-decoration: none;
      }

    }

    &.m--opened > a {
      &::after {
        @include background-svg($svg-arrow-curve, $white);
        width: 35px;
        height: 35px;
        transform: translate(0, -10px) rotate(-150deg) ;
      }

    }

  }

  .level0 {
    &.submenu {
      height: 0;
      overflow: hidden;
      transition: height ease 0.3s;
      padding: 0 32px;

      .column:last-child {
        margin-bottom: 20px;
      }
    }
  }

  .level1 {
    & > a {
      display: block;
      padding: 4px 0;
    }
  }

}

@media screen and (min-width:$bp-l) {

  .ldv-navigation {
    overflow-y: visible;
    display: inline-block;
    background-color: $white;
    color: $dark;
    height: 100%;

    & > ul {
      display: flex;
      height: 100%;
    }

    .category-item.level0 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      &:hover::before, &.active::before {
        bottom: 0;
        content: '';
        display: block;
        height: 35px;
        left: 50%;
        position: absolute;
        transform: translate(-18px, 0) rotate(-60deg);
        width: 35px;
        z-index: 1;
      }

      a {
        position: relative;
        z-index: 1;
      }

      &::after {
        background: none;
        display: none;
        height: 48px;
        left: -420px;
        width: calc(100% + 420px);
      }

      &:hover::after {
        display: block;
      }

      &:first-child > a {
        padding-top: 0px;
      }

      .level0.submenu {
        display: flex;
        height: auto;
        position: fixed;
        top: 108px;
        width: 90vw;
        max-width: 1090px;
        left: 50%;
        transform: translate(-50%,0);
        padding: 0;
        color: $white;
        border-radius: 0 0 16px 16px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease, top 0.3s ease;

        .m--shrinked & {
          top: 80px;
        }

        .column {
          width: 32%;
          margin: 0;
          padding: 0;
          & > ul {
            padding: 30px;
          }
        }

        &::after {
          content: '';
          display: block;
          width: 36%;
          margin-left: auto;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center 46%;
        }
      }

      &.m--maps {
        &::before {
          @include background-svg($svg-arrow-curve, $blue-d2);
        }

        .level0.submenu {
          background-color: $blue-d;

          .column {
            background-color: $blue-d;
            &:first-child {
              background-color: $blue-d2;
            }
          }

          &::after {
            background-image: url('../../images/menu/maps.png');
          }
        }
      }

      &.m--guides {
        &::before {
          @include background-svg($svg-arrow-curve, $orange-d);
        }

        .level0.submenu {
          background-color: $orange;

          .column {
            background-color: $orange;
            &:first-child {
              background-color: $orange-d;
            }
          }

          &::after {
            background-image: url('../../images/menu/guides.png');
          }
        }
      }

      &.m--books {
        &::before {
          @include background-svg($svg-arrow-curve, $green-d);
        }

        .level0.submenu {
          background-color: $green;

          .column {
            background-color: $green;
            &:first-child {
              background-color: $green-d;
            }
          }

          &::after {
            background-image: url('../../images/menu/books.png');
          }
        }
      }
    }

    .level-top {
      & > a {
        font-size: 16px;
        padding: 0 20px;
        &::after {
          display: none;
        }

        &:hover,
        &:visited,
        &:active {
          color: $dark;
          text-decoration: none;
        }

      }

    }

    .level0 {
      &.submenu {
        display: none;
      }
      .column:first-child {
        padding-top: 15px;
      }
    }

    .category-item.level1 {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        &:hover {
          color: $white;
          font-weight: $fw-bold;
        }

        &:active,
        &:visited {
          color: $white;
        }

      }

    }

  }

  .page-wrapper {
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: transparent;
      z-index: 500;
      transition: background-color ease 0.3s, height step-end 0.3s;
    }

    &.m--overlayed, .m--page-overlayed & {
      &::after {
        height: 100%;
        background-color: rgba($dark, 0.9);
        transition: background-color ease 0.3s;
      }
    }

  }

}

