.ldv-filters {
  align-items: center;
  background-color: $white;
  border-radius: 21px;
  box-shadow: 0px 4px 8px 0px rgba($black, 0.2);
  display: flex;
  flex-flow: column nowrap;
  max-width: 830px;
  margin: 0 auto;
  overflow: hidden;
  width: 90%;
}

.ldv-filters__toggle {
  display: none;

  &:checked {
    ~ .ldv-filters__item {
      max-height: 500px;
    }
  }
}

.ldv-filters__toggle-label {
  cursor: pointer;
  display: block;
  flex-shrink: 0;
  font-style: italic;
  height: 42px;
  line-height: 42px;
  padding: 0 48px 0 20px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  .button {
    background-color: $blue;
    border-radius: 100%;
    color: $white;
    height: 36px;
    line-height: 36px;
    padding: 0;
    position: absolute;
    right: 4px;
    text-align: center;
    top: 3px;
    width: 36px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.ldv-filters__item {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease;
}



@media screen and (min-width:$bp-m) {

  .ldv-filters {
    border-radius: 35px;
    flex-flow: row nowrap;
    height: 70px;
    max-height: none;
    overflow: visible;
    padding: 0 20px;
  }

  .ldv-filters__toggle-label {
    display: none;
  }

  .ldv-filters__item {
    max-height: none !important;
    overflow: visible;
  }
}
